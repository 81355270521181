import React from 'react';
import './BrandService.scss';

const BrandsService = ({data}) => {
  return (
    <div className="section-inner">
        <section className='lpcasino__service_box'>
          {
            data.map((item) => {
              return (
                <div className="lpcasino__service_single" key={item.id}>
                  <img src={item.icon} />
                  <h3>{item.serviceTitle}</h3>
                  <p>{item.serviceProvider}</p>
                </div>
              )
            })
          }
        </section>
      </div>
  )
}

export default BrandsService