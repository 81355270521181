import brands from "./brands";
import Contact from "./Contact";
import Carousel from "./Slider";
import ourTeamBg from "../../assets/team-bg.png";
import { NavLink } from "react-router-dom";
import "./Home.scss";

const steps = [
  {
    id: 1,
    title: "Join",
    text: "Join WinningPartner Network today and start earning today.",
  },
  {
    id: 2,
    title: "Promote",
    text: "Get immediate access to a range of marketing materials.",
  },
  {
    id: 3,
    title: "Earn",
    text: "Watch your traffic turn into cash.",
  },
];

const benefits = [
  {
    text: "100 or more",
    percent: 50,
  },
  {
    text: "71 - 100",
    percent: 40,
  },
  {
    text: "36 - 70",
    percent: 35,
  },
  {
    text: "1 - 35",
    percent: 25,
  },
];

const Home = () => {
  return (
    <div className="home-content">
      <Carousel />
      <div id="steps">
        <div className="innerWrap">
          <div className="step__list">
            {steps.map(({ id, title, text }) => (
              <section key={id} className="step__item">
                <span className="num">{id}</span>
                <h3>{title}</h3>
                <p>{text}</p>
              </section>
            ))}
          </div>
        </div>
        <hr />
        <center><a className="button-signup-page green-link" href="/signup">Sign Up</a></center>
      </div>

      <div id="benefits" className="content">
        <div className="innerWrap">
          <h2 className="block__title">Commission Structure</h2>
          <div className="benefits__row">
            <div className="benefits__description">
              <p>
                Supercharge your revenue with our generous commission structure;
                more players, more cash in your wallet.
              </p>
              <ul className="mainList">
                <li>Up to 50% Revenue Share</li>
                <li>Fair Commission Structure</li>
                <li>Start Earning Today</li>
              </ul>
            </div>
            <div className="benefits__table" id="conversionTable">
              <div className="data-title">
                <h4>Conversion Level</h4>
                <h4>Payout</h4>
              </div>
              {benefits.map(({ text, percent }) => (
                <div key={percent} className="dataRow">
                  <span>{text}</span>
                  <span>{percent}%</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div id="brands">
        <div className="innerWrap">
          <h2 className="block__title">Our Brands</h2>

          <div className="brands__list">
            {brands.map(({ id, url, className, path_img }) => (
              <a
                key={id}
                href={url ? url : "#"}
                target="_blank"
                rel="noopener noreferrer"
                className={`brand ${className}`}
              >
                <img src={path_img} alt={url} />
              </a>
            ))}
          </div>
        </div>
      </div>

      <div id="about" className="content">
        <div className="innerWrap">
          <h2 className="block__title">Who are Winning Partner?</h2>
          <p>
            We're a team of iGaming marketing specialists based in St Albans.
            Operated by Traffic Label, our team has vast experience within affiliate marketing from an operator and
            affiliate perspective.
            We have a vast array of quality casino and bingo sites in our portfolio; from Miami Jackpots to RichRide
            we've got your casino needs covered.
          </p>
          <p>
            Here at Winning Partner we recognise the value of our partners!
            After all, successful partnerships are the foundations of any great affiliate network.
            Through our years of experience we have developed a winning formula!
          </p>
          <h5 className="about-us-title">Trust</h5>
          <p>Trust is vital to our partnerships, however trust requires cooperation and investment from both parties.
            We aim for full transparency in communications - from who we are, to the structures of our deals and
            commission, we're an open book!</p>
          <h5 className="about-us-title">Adaptability</h5>
          <p>Adapting to situations is vital in our industry. We recognise that no two affiliates are the same.
            Therefore we will adapt to each specific scenario,
            each affiliate will be able to receive a deal that caters to their needs to create a mutually beneficial
            arrangement.</p>
          <h5 className="about-us-title">Dedication</h5>
          <p>We're committed in our efforts to establishing Winning Partner as the number one affiliate network.
            However, we know this isn't a quick process, therefore we've built a team solely focused on Winning Partner
            -
            each affiliate receives a dedicated affiliate manager to offer personalised guidance and expertise.</p>
        </div>
      </div>

      <div
        id="our-team"
        style={{ backgroundImage: `url(${ourTeamBg})` }}
        className="our-team"
      >
        <div className="innerWrap">
          <h2 className="block__title">Meet our team</h2>
          <NavLink to="/profiles">View profiles</NavLink>
        </div>
      </div>

      <Contact />
    </div>
  );
};

export default Home;
