import { Redirect, Route } from "react-router-dom";
import useAuth from "./auth/useAuth";
import { Alert, Box, Container } from "@mui/material";

const unconfirmedBox = () => (
  <>
    <Container className={"center-block"} maxWidth="sm">
      <Alert severity="success">
        Login Successful - your account is awaiting confirmation from an affiliate manager
      </Alert>
    </Container>
  </>
);

export const PrivateRoute = ({ component: Component, authed, ...rest }) => {
  const auth = useAuth();
  const hasToken = Boolean(window?.localStorage?.getItem("accessToken"));
  return (
    <Route
      {...rest}
      render={(props) =>
        auth.isAuthenticated
          ? (auth.user?.confirmed ? <Component {...props} /> : unconfirmedBox())
          : (hasToken ? <Box /> : <Redirect to="/" />)
      }
    />
  );
};
