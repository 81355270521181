import React from 'react';
import './BrandsPayment.scss';

const BrandsPayment = ({ data }) => {
  return (
    <section className="lpcasino__payment">
        <div className="section-inner">
            <div className="lpcasino__payment_box">
                <div className="lpcasino__payment_left">
                    <div className="section-heading section-heading-align-left">
                        <h3>{data.title.split(' ')[0]}</h3>
                        <h3>{data.title.split(' ')[1]}</h3>
                    </div>
                    <p>{data.paragraph}</p>
                </div>
                <div className="lpcasino__payment_right">
                    {data.img.map((item,index) => <img key={index} src={item} alt="" />)}
                </div>
            </div>
        </div>
        <div>

        </div>
    </section>
  )
}

export default BrandsPayment