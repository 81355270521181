import React from 'react';
import './BrandsReviews.scss';
import InfiniteCarousel from 'react-leaf-carousel';

const BrandsReviews = ({ data }) => {
  return (
    <section className='lpcasino__reviews'>
        <div className="section-heading">
            <h3>{data.title.split(' ')[0]}</h3>
            <h3>{data.title.split(' ')[1]}</h3>
        </div>

        <div className='lpcasino__review_single_box'>
            <InfiniteCarousel
                dots={true}
                showSides={true}
                sideSize={.4}
                slidesToScroll={1}
                slidesToShow={1}
                arrows={false}
                scrollOnDevice={true}
                autoCycle={true}
                cycleInterval={5000}
                pauseOnHover={true}
            >
                {
                    data.item.map((item, index) => {
                        return (
                            <div className='lpcasino__review_single_item' key={index}>
                                <div className="lpcasino__review_single_img_top">
                                    <img id="review_single_logo" src={item.image} alt="" />
                                </div>
                                <p>{item.paragraph}</p>
                                <div id="review_single_star" className="lpcasino__review_single_img_bottom">
                                    <img src={item.rating} alt="" />
                                </div>
                                <h5>
                                    <a href={item.siteLink}>
                                        {item.siteText}
                                    </a>
                                </h5>
                            </div>
                        )
                    })
                }
            </InfiniteCarousel>
        </div>

        <div className="lpcasino__review_button_box">
            <div>
                {
                    data.button.map((item,index) => {
                        return (
                            <button key={index}>
                                <a href={item.link}>{item.title}</a>
                            </button>
                        )
                    })
                }
            </div>
        </div>
    </section>
  )
}

export default BrandsReviews