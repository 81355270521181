import React from 'react';
import {
  bannerIcons,
  services,
  brands,
  flagIcons,
  target_markets_desc,
  target_markets_imgs,
  poweredby_imgs,
  withus_imgs,
} from './IGBConferenceData';

import NumberImg from '../../assets/Pages/IGBConference/withus/Number.png';

import './IgbConference.scss';

const sliceIntoChunks = (arr, chunkSize) => {
  const res = [];
  for (let i = 0; i < arr.length; i += chunkSize) {
    const chunk = arr.slice(i, i + chunkSize);
    res.push(chunk);
  }
  return res;
};

const IGBConference = () => (
  <>
    <section id="banner" className="banner-container">
      <div className="section-inner">
        <h1 className="outline">Sign Up by 15 February</h1>
        <h2>And get a commission</h2>
        <h1>Bonus of up to £500!*</h1>
        {bannerIcons.map((bannerIcon) => (
          <img key={bannerIcon.id} id={bannerIcon.imageId} src={bannerIcon.img} alt={bannerIcon.alt} />
        ))}
        <a
          className="get-started"
          target="_blank"
          rel="noopener noreferrer nofollow"
          href="https://join.skype.com/invite/GRTMv9LeqgA0"
        >
          <button className="green-button">Get Started</button>
        </a>
      </div>
    </section>

    <section id="services" className="service-container">
      <div className="section-inner">
        <div className="service__list">
          {services.map((service) => (
            <div key={service.id} className="service__item">
              <img src={service.image} alt="service" />
              <p>{service.title}</p>
            </div>
          ))}
        </div>
      </div>
    </section>

    <section id="target-markets" className="target-markets-container">
      <div className="section-inner">
        <h1>Target Markets</h1>

        <div className="target-markets_row">
          <div className="target-markets__left">
            <div className="target-markets__flag">
              {flagIcons.map((item, index) => (
                <div key={index} className="flag_wrap">
                  <img src={item.img} alt={item.alt} />
                  <p>{item.imageId}</p>
                </div>
              ))}
            </div>

            <ul>
              {target_markets_desc.map((item, index) => {
                const str = item.description.split(item.main);
                return (
                  <li key={index}>
                    {str[0] === '' ? (
                      <>
                        <span>{item.main}</span>
                        {str[1]}
                      </>
                    ) : (
                      <>
                        {str[0]}
                        <span>{item.main}</span>
                        {str[1]}
                      </>
                    )}
                  </li>
                );
              })}
            </ul>
          </div>

          <div className="target-markets__right">
            <span>20</span>
            <p>years of experience as an affiliate and operator</p>
          </div>
        </div>
      </div>

      {target_markets_imgs.map((item, index) => (
        <img key={index} src={item.img} id={item.imgId} alt={item.imgId} />
      ))}
    </section>

    <section id="poweredby" className="poweredby-container">
      <div className="section-inner">
        <h1>
          Powered <span>by</span>
        </h1>

        <div className="poweredby__imgwrap">
          {poweredby_imgs.map((item, index) => (
            <div className="poweredby__img" key={index}>
              <img src={item.img} id={item.imgId} />
            </div>
          ))}
        </div>

        <a
          className="get-started"
          target="_blank"
          rel="noopener noreferrer nofollow"
          href="https://join.skype.com/invite/GRTMv9LeqgA0"
        >
          <button className="green-button">Get Started</button>
        </a>
      </div>
    </section>

    <section id="withus" className="withus-container">
      <div className="section-inner">
        <h1>Already with us</h1>

        <div className="withus__imgwrap">
          {withus_imgs.map((item, index) => (
            <div className="withus__img" key={index}>
              <img src={item.img} id={item.imgId} />
            </div>
          ))}
        </div>

        <h2>+140 others</h2>
        <img className="number-img" src={NumberImg} alt="140" />
      </div>
    </section>

    <section id="brands">
      <div className="section-inner">
        <h1>
          Our <span>Brands</span>
        </h1>
        <div className="brands__list">
          {brands.map(({ id, url, className, path_img }) => (
            <a
              key={id}
              href={url ? url : '#'}
              target="_blank"
              rel="noopener noreferrer"
              className={`brand ${className}`}
            >
              <img src={path_img} alt={url} />
            </a>
          ))}
        </div>
      </div>
      <div className="brands__list__mobile">
        {sliceIntoChunks(brands, 2).map((item, index) => (
          <div className="brands__pair" key={index}>
            <a
              href={item[0].url ? item[0].url : '#'}
              target="_blank"
              rel="noopener noreferrer"
              className={`brand ${item[0].className}`}
            >
              <img src={item[0].path_img} alt={item[0].url} />
            </a>
            <a
              href={item[1].url ? item[1].url : '#'}
              target="_blank"
              rel="noopener noreferrer"
              className={`brand ${item[1].className}`}
            >
              <img src={item[1].path_img} alt={item[1].url} />
            </a>
          </div>
        ))}
      </div>
      <a
        className="get-started"
        target="_blank"
        rel="noopener noreferrer nofollow"
        href="https://join.skype.com/invite/GRTMv9LeqgA0"
      >
        <button className="green-button">Get Started</button>
      </a>
      <p>
        *Upon registration, the individual shall be eligible for an exclusive bonus of up to £500. The bonus amount
        shall be determined through discussion and may vary based on the individual's traffic volume, traffic type, and
        promoted brands.
      </p>
    </section>
  </>
);

export default IGBConference;
