import GamblingLicenceImg from '../../../assets/brands/BBCasino/Icons/Gambling-Licence-Icon.svg';
import AffiliateProgrammeIcon from '../../../assets/brands/BBCasino/Icons/Affiliate-Programme-Icon.svg';
import ProductFocusIcon from '../../../assets/brands/BBCasino/Icons/Product-Focus-Icon.svg';
import AllowedCountriesIcon from '../../../assets/brands/BBCasino/Icons/Allowed-Countries-Icon.svg';
import SoftwareProviderIcon from '../../../assets/brands/BBCasino/Icons/Software-Provider-Icon.svg';
import NumberOfGames from '../../../assets/brands/BBCasino/Icons/Number-of-Games.svg';
import NetentImage from '../../../assets/brands/BBCasino/Logos/Netent.png';
import Alchemy from '../../../assets/brands/BBCasino/Logos/Alchemy.png';
import BigTimeGaming from '../../../assets/brands/BBCasino/Logos/BigTimeGaming.png';
import Blueprint from '../../../assets/brands/BBCasino/Logos/BluePrint.png';
import Elk from '../../../assets/brands/BBCasino/Logos/Elk.png';
import EvolutionImage from '../../../assets/brands/BBCasino/Logos/Evolution.png';
import Microgaming from '../../../assets/brands/BBCasino/Logos/Microgaming.png';
import Northernlights from '../../../assets/brands/BBCasino/Logos/Northernlights.png';
import ThunderKick from '../../../assets/brands/BBCasino/Logos/Thunderkick.png';
import VisaIcon from '../../../assets/brands/BBCasino/Logos/Visa.png';
import MasterCard from '../../../assets/brands/BBCasino/Logos/MasterCard.png';
import Paypal from '../../../assets/brands/BBCasino/Logos/PayPal.png';
import CustomerSupport from '../../../assets/brands/BBCasino/blocks/Customer-Support-Hours.png';
import LiveChat from '../../../assets/brands/BBCasino/blocks/Live-Chat.png';
import MinimumDeposit from '../../../assets/brands/BBCasino/blocks/Minimum-Deposit.png';
import WithdrawalTimespan from '../../../assets/brands/BBCasino/blocks/Withdrawal-Timespan.png';
import FirstScreenshot from '../../../assets/brands/MiamiJackpots/miami-screenshot-1.png';
import SecondScreenshot from '../../../assets/brands/MiamiJackpots/miami-screenshot-2.png';
import ThirdScreenshot from '../../../assets/brands/MiamiJackpots/miami-screenshot-3.png';
import Stars from '../../../assets/brands/BBCasino/Stars.png';
import OlbgLogo from '../../../assets/brands/BBCasino/olbg-avatar.png';
import Skrill from '../../../assets/brands/ThreeCasino/Skrill.png';
import HeaderImg from '../../../assets/brands/MiamiJackpots/Miami-Header-Image.png';
import Paysafe from '../../../assets/brands/BBCasino/Logos/PaySafe.png';
import MiamiOffer from '../../../assets/brands/MiamiJackpots/Miami-Offer.png';

export default {
    hero: {
        title: "MIAMI JACKPOTS",
        paragraph: "Miami Jackpots Affiliate Programme. This UK-Licensed casino is fast, responsive and works on every device, be it on desktop or mobile.",
        button: [
            {
                id: 1,
                title: "VISIT MIAMI CASINO",
                link: 'https://www.miamijackpots.com/',
            },
            {
                id: 2,
                title: "PROMOTE MIAMI CASINO",
                link: 'https://winningpartner.com/signup',
            }
        ],
        img: HeaderImg,
    },
    service: [
        {
            id: 1,
            icon: GamblingLicenceImg,
            serviceTitle: 'Gambling Licence',
            serviceProvider: 'UK (Acc, No. 41645)'
        },
        {
            id: 2,
            icon: AffiliateProgrammeIcon,
            serviceTitle: 'Affiliate Programme',
            serviceProvider: 'Winning Partner'
        },
        {
            id: 3,
            icon: ProductFocusIcon,
            serviceTitle: 'Product Focus',
            serviceProvider: 'Online Slots and Casino games'
        },
        {
            id: 4,
            icon: AllowedCountriesIcon,
            serviceTitle: 'Allowed Countries',
            serviceProvider: 'UK, CA, ES'
        },
        {
            id: 5,
            icon: SoftwareProviderIcon,
            serviceTitle: 'Software Provider',
            serviceProvider: 'SkillOnNet'
        },
        {
            id: 6,
            icon: NumberOfGames,
            serviceTitle: 'Number of Games',
            serviceProvider: 'Over 200 games'
        },   
    ],
    screenshots: {
        title: 'Site Screenshots',
        images: [
            FirstScreenshot,
            SecondScreenshot,
            ThirdScreenshot
        ]
    },
    commission: {
        title: "Commission Models",
        items: [
            {
                id: 1,
                number: 1,
                text: 'Default 30% Revenue Share.',
            },
            {
                id: 2,
                number: 2,
                text: 'CPA and Hybrid deals available on request',
            }
        ]
    },
    providers: {
        title: 'Game Providers',
        paragraph: 'Great selecion of game developers, from the big names such as NetEnt and Blueprint Gaming to smaller companies like SpinPlay Games and Crazy Tooth Studio',
        images: [
            NetentImage,
            Alchemy,
            BigTimeGaming,
            Blueprint,
            Elk,
            EvolutionImage,
            Skrill,
            Microgaming,
            Northernlights,
            ThunderKick,
        ]
    },
    standard: {
        title: 'Standard Welcome Offer',
        paragraph: 'Once players sign up and start playing, they are in for a generous welcome bonus',
        item: [
            {
                id: 1,
                title: '',
                paragraph: '',
                img: MiamiOffer,
            },
            
        ]
    },
    payments: {
        title: 'Payment Methods',
        paragraph: 'Our punters get a great number of the most popular payment options. All deposit methods are fast safe and secure.',
        img: [
            VisaIcon,
            MasterCard,
            Skrill,
            Paypal,
            Paysafe,
        ]
    },
    blocks: [
        {
            id: 1,
            title: 'Minimum Deposit',
            text: '£10 deposit, £20 withdrawal',
            img: MinimumDeposit,
        },
        {
            id: 2,
            title: 'Withdrawal Timespan',
            text: '1 business day',
            img: WithdrawalTimespan,
        },
        {
            id: 3,
            title: 'Customer Support Hours',
            text: 'Email, Live chat',
            img: LiveChat,
        },
        {
            id: 4,
            title: 'Customer Support Methods',
            text: '6am - Midnight',
            img: CustomerSupport,
        },
    ],
    reviews: {
        title: 'Partner Reviews',
        item: [
            {
                image: OlbgLogo,
                paragraph: 'OLBG’s collaboration with Winning Partner has been propelled by their transparency and responsiveness. The team is always eager to provide guidance and assistance whenever required, and we are looking forward to continued success with their brands.',
                rating: Stars,
                siteText: 'Olbg.com',
                siteLink: 'https://www.olbg.com/'
            }
        ],
        button: [
            {
                id: 1,
                title: "VISIT MIAMI CASINO",
                link: 'https://www.miamijackpots.com/',
            },
            {
                id: 2,
                title: "PROMOTE MIAMI CASINO",
                link: 'https://winningpartner.com/signup',
            }
        ],
    }
}