import { useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import { Dashboard } from "../../components/Dashboard/Dashboard";
import DataTable from "react-data-table-component";
import { Circular } from "../../components/Circular/Circular";
import connectTopLine from "../../components/HOC/connectTopLine";
import { applyCurrencyFormatters } from "../../common/helper";
import "bootstrap/dist/css/bootstrap.min.css";
import fetchData from "../../api/data";
import Export from "../../components/Export/Export";
import { toastApiError } from "../../utils/toastApiError";

const columns = [
  {
    name: "Casinos",
    selector: (row) => row.brandName,
    sortable: true,
  },
  {
    name: "Clicks",
    selector: (row) => row.clicks,
    sortable: true,
    right: true,
  },
  {
    name: "Registrations",
    selector: (row) => row.registrations,
    sortable: true,
    right: true,
  },
  {
    name: "FTDs",
    selector: (row) => row.ftds,
    sortable: true,
    right: true,
  },
  {
    name: "No of deposits",
    selector: (row) => row.numberOfDeposits,
    sortable: true,
  },
  {
    name: "Deposits",
    selector: (row) => row.deposits,
    sortable: true,
    format: "currency",
    right: true,
  },
  {
    name: "Net Cash",
    selector: (row) => row.netCash,
    sortable: true,
    format: "currency",
    right: true,
  },
  {
    name: "Rev Share Commission",
    selector: (row) => row.revShareCommission,
    sortable: true,
    format: "currency",
    right: true,
  },
  {
    name: "CPA Commission",
    selector: (row) => row.cpaCommission,
    sortable: true,
    format: "currency",
    right: true,
  },
];

const perPageList = [10, 50, 100];

const Casinos = ({ datesFilter, brandsFilter }) => {
  const [stats, setStats] = useState({});
  const [reports, setReports] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    return fetchData({
      url: `/api/v3/reports/casinos?page=1&per_page=10000&${brandsFilter}&${datesFilter}`,
      onSuccess: (response) => {
        setReports(response.data.reports);
        setStats(response.data.totalsReport);
      },
      onError: toastApiError,
      onCallback: () => setLoading(false),
    });
  }, [brandsFilter, datesFilter]);

  return (
    <Row>
      <Dashboard stats={stats} />
      <div className="data__wrapper">
        <DataTable
          title="Casinos"
          columns={applyCurrencyFormatters(columns)}
          data={reports}
          progressPending={loading}
          pagination
          progressComponent={<Circular />}
          paginationRowsPerPageOptions={perPageList}
          actions={<Export rows={reports} />}
        />
      </div>
    </Row>
  );
};

export default connectTopLine(true, true, Casinos);
