import { Assets } from "../../pages/Assets";
import { BrandsReport } from "../../pages/BrandsReport";
import { DynamicVariables } from "../../pages/DynamicVariables";
import { Players } from "../../pages/Players";
import { Daily } from "../../pages/Daily";
import { Monthly } from "../../pages/Monthly";
import { Casinos } from "../../pages/Casinos";
import { Campaigns } from "../../pages/Campaigns";
import { Messages } from "../../pages/Messages";
import { Reports } from "../../pages/Reports";
import { Subaffilation } from "../../pages/Subaffilation";
import { Profile } from "../../pages/Profile";
import { Route, Switch } from "react-router-dom";
import "./styles.css";

const routes = [
  {
    id: 1,
    page: Monthly,
    path: "/admin/overview",
    exact: true,
  },
  {
    id: 2,
    page: DynamicVariables,
    path: "/admin/dynamic_variable",
    exact: true,
  },
  {
    id: 3,
    page: Daily,
    path: "/admin/daily",
    exact: true,
  },
  {
    id: 4,
    page: BrandsReport,
    path: "/admin/brands",
    exact: true,
  },
  {
    id: 5,
    page: Casinos,
    path: "/admin/casinos",
    exact: true,
  },
  {
    id: 11,
    page: Campaigns,
    path: "/admin/campaigns",
    exact: true,
  },
  {
    id: 12,
    page: Assets,
    path: "/admin/assets",
    exact: true,
  },
  {
    id: 13,
    page: Reports,
    path: "/admin/reports",
    exact: true,
  },
  {
    id: 14,
    page: Subaffilation,
    path: "/admin/subaffilation",
    exact: true,
  },
  {
    id: 15,
    page: Profile,
    path: "/admin/profile",
    exact: false,
  },
  {
    id: 16,
    page: Players,
    path: "/admin/players_report",
    exact: false,
  },
  {
    id: 17,
    page: Messages,
    path: "/admin/messages",
    exact: true,
  },
];

const Router = () => {
  return (
    <div className="main-content">
      <Switch>
        {routes.map(({ id, path, page, exact }) => (
          <Route exact={exact} key={id} path={path} component={page} />
        ))}
      </Switch>
    </div>
  );
};

export default Router;
