import React from 'react';
import BrandsHero from '../../../components/Brands/BrandsHero/BrandsHero';
import './BBCasino.scss';
import BrandsService from '../../../components/Brands/BrandsService/BrandsService';
import BBCasinoData from './BBCasinoData';
import BrandsCommission from '../../../components/Brands/BrandsCommission/BrandsCommission';
import BrandsProvider from '../../../components/Brands/BrandsProvider/BrandsProvider';
import BrandsPayment from '../../../components/Brands/BrandsPayment/BrandsPayment';
import BrandsStandard from '../../../components/Brands/BrandsStandard/BrandsStandard';
import BrandsBlocks from '../../../components/Brands/BrandsBlocks/BrandsBlocks';
import BrandsScreenshots from '../../../components/Brands/BrandsScreenshots/BrandsScreenshots';
import BrandsReviews from '../../../components/Brands/BrandsReviews/BrandsReviews';
import HelmetMeta from '../../../components/HelmetMeta/HelmetMeta';

const BBCasino = () => {
  return (
    <>
      <HelmetMeta title={'BBCasino Affiliate Programme - Sign up now'} />
      <main className="lpcasino">
          <BrandsHero data={BBCasinoData.hero} />
          <BrandsService data={BBCasinoData.service} />
          <BrandsScreenshots data={BBCasinoData.screenshots} />
          <BrandsCommission data={BBCasinoData.commission} />
          <BrandsProvider data={BBCasinoData.providers} />
          <BrandsStandard data={BBCasinoData.standard} />
          <BrandsPayment data={BBCasinoData.payments} />
          <BrandsBlocks data={BBCasinoData.blocks} />
          <BrandsReviews data={BBCasinoData.reviews} />
      </main>
    </>
  )
}

export default BBCasino;
