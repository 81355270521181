import PhilPhoto from "../../assets/Phil.png";
import MashaPhoto from "../../assets/masha.png";
import BohdanPhoto from "../../assets/bohdan.jpg";

const Profiles = () => (
  <section className="profiles">
    <div className="innerWrap">
      <h1 className="block__title">Meet our team</h1>
      <div className="profiles__list">
        <div className="profile">
          <img src={BohdanPhoto} alt="Bohdan" style={{ height: "200px", width: "200px" }} />
          <div className="profile__descr">
            <span className="profile__title">Bohdan</span>
            <span className="profile__position">Affiliate Manager</span>
            <span className="profile__text">affiliates@winningpartner.com</span>
          </div>
        </div>
        <div className="profile">
          <img src={MashaPhoto} alt="Masha" style={{ height: "210px", width: "200px" }} />
          <div className="profile__descr">
            <span className="profile__title">Masha</span>
            <span className="profile__position">Affiliate Manager</span>
            <span className="profile__text">affiliates@winningpartner.com</span>
          </div>
        </div>
        <div className="profile">
          <img src={PhilPhoto} alt="Monica" style={{ height: "210px", width: "205px" }} />
          <div className="profile__descr">
            <span className="profile__title">Phil</span>
            <span className="profile__position">Affiliate Manager</span>
            <span className="profile__text">phil@trafficlabel.com</span>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default Profiles;
