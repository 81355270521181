import { NavLink } from "react-router-dom";
import gamcom from "../../assets/ukgc.png";
import gamcare from "../../assets/gamcare-logo.png";
import gambleaware from "../../assets/gambling-aware-logo.png";
import mga from "../../assets/mga.png";


const Footer = () => {
  return (
    <footer className="footer">
      <div className="innerWrap">
        <div className="footer__top_row">
          <div className="footer__link">
            <NavLink to="/terms" activeClassName="footer-link-active">Terms and Conditions</NavLink>
          </div>
          <div className="footer__link">
            <NavLink to="/privacy-policy" activeClassName="footer-link-active">Privacy Policy</NavLink>
          </div>
          <div className="footer__link">
            <NavLink to="/faqs" activeClassName="footer-link-active">FAQs</NavLink>
          </div>
          <div className="footer__link">
            <NavLink to="/testimonials" activeClassName="footer-link-active">Testimonials</NavLink>
          </div>
        </div>

        <div className="footer__second_row">
          <a target="_blank" rel="noopener noreferrer" href="https://www.gamblingcommission.gov.uk/home.aspx"><img
            style={{ paddingRight: "20px" }} src={gamcom} width={125} height={30} /></a>
          <a target="_blank" rel="noopener noreferrer" href="https://www.mga.org.mt/"><img
            style={{ paddingRight: "20px" }} src={mga} width={216} height={42} /></a>
          <a target="_blank" rel="noopener noreferrer" href="https://www.begambleaware.org/"><img
            style={{ paddingRight: "20px" }} src={gambleaware} width={160} height={21} /></a>
          <a target="_blank" rel="noopener noreferrer" href="https://www.gamcare.org.uk/"><img
            style={{ paddingRight: "20px" }} src={gamcare} width={62} height={62} /></a>
          {/* <a target="_blank" href="https://www.gamstop.co.uk/"><img style={{paddingRight: '20px'}} src={gamstop} width={216} height={42}/></a> */}
          {/* <img src={eighteenplus} width={60} height={60}/> */}
        </div>

        <div className="footer__third_row">
          <span>London, United Kingdom </span>
          <span>&copy; Copyright 2021 WinningPartner Network, All Rights Reserved</span>
          <a href="mailto:affiliates@winningpartner.com" title="Send us an email"
             className="footer__email">affiliates@winningpartner.com</a>
        </div>

      </div>
    </footer>
  );
};

export default Footer;
