import React from 'react';
import './BrandsStandard.scss';
import { useLocation } from 'react-router-dom';
import InfiniteCarousel from 'react-leaf-carousel';

const checkLocation = (location, path) => {
    return location.pathname.endsWith(path);
}

const ShowStandard = ({ data }) => {
    const location = useLocation();
    if(checkLocation(location, '/brands/BBCasino')){
        return (
            <div class="section-inner"> 
                <div className="lpcasino__brand_item_container">
                    {
                        data.item.map((item) => {
                            return (
                                <div className="lpcasino__brand_standard_single">
                                    <img src={item.img} alt="" />
                                    <h4>{item.title}</h4>
                                    <p>{item.paragraph}</p>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        )
    } else if (checkLocation(location,'/brands/MiamiJackpots') || checkLocation(location, '/brands/OinkBingo') || checkLocation(location,'/brands/miamijackpots')) {
        return <div class="section-inner"> 
                    <div className="lpcasino__brand_item_container_one">
                        {
                            data.item.map((item) => {
                                return (
                                    <div className="lpcasino__brand_standard_single" key={item.id}>
                                        {location.pathname.endsWith('/OinkBingo') ? '' : <img src={item.img} />}
                                        <picture>
                                            <source media="(max-width: 500px)" srcSet={item.imgMobile} />
                                            <img src={item.img} alt="Offer Image" />
                                        </picture>
                                        <h4>{item.title}</h4>
                                        {checkLocation(location, !'/brands/OinkBingo') && <p>{item.paragraph}</p>}
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
    } else {
        return (
            <div className="lpcasino__brand_item_container_new">
                <InfiniteCarousel
                    breakpoints={[
                        {
                        breakpoint: 700,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                        },
                        },
                    ]}
                    dots={true}
                    showSides={true}
                    sidesOpacity={1}
                    sideSize={.4}
                    slidesToScroll={1}
                    slidesToShow={3}
                    arrows={false}
                    scrollOnDevice={true}
                    autoCycle={true}
                    cycleInterval={3500}
                    pauseOnHover={true}
                >
                    {
                            data.item.map((item) => {
                                return (
                                    <div className="lpcasino__brand_standard_single">
                                        <img src={item.img} alt="" />
                                        <h4>{item.title}</h4>
                                        <p>{item.paragraph}</p>
                                    </div>
                                )
                            })
                        }
                </InfiniteCarousel>
            </div>
        )
    }
}

const BrandsStandard = ({ data }) => {
  const location = useLocation();
  return (
    <section className="lpcasino__brand_standard">
        <div className="lpcasino__brand_upper">
            <div className="section-inner">
                <h3>{data.title}</h3>
                <p>{data.paragraph}</p>
            </div>
        </div>
        <div className="lpcasino__brand_lower">
            <ShowStandard data={data} location={location} />           
        </div>
    </section>
  )
}

export default BrandsStandard