import React from 'react';
import Pdf from "../../../assets/what-to-expect.pdf"
import '../Pdf.scss'

const PDF_Expect = () => {

    return (
        <div className="pdf">
            <a href={Pdf} className="pdf__link"><span>See more</span> <span><i className='fas fa-angle-double-right'></i></span></a>
        </div>
    );
}

export default PDF_Expect
