import React from 'react'
import SingleReview from './SingleReview'
import Contact from '../Home/Contact'
import './reviews.scss'
import ScrollButton from '../../components/ScrollButton/ScrollButton'
import ReviewsData from './ReviewsData'

const Review = () => {
  return (
    <section className="review">
      {/* This section is for the background-images displayed on the site */}
      <div className="review__background_assets">
      {/* The circle image */}
          <svg width="154" height="284" viewBox="0 0 154 284" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M114.9 281.419C39.4717 268.355 -11.0482 195.242 2.23021 118.36C15.5087 41.4784 87.6157 -10.3973 163.045 2.66623C238.473 15.7297 288.993 88.8431 275.715 165.725C262.436 242.606 190.329 294.482 114.9 281.419ZM151.319 70.5559C112.502 63.8332 75.4109 90.5174 68.5776 130.082C61.7442 169.647 87.7311 207.256 126.548 213.978C165.365 220.701 202.456 194.017 209.29 154.452C216.162 114.663 190.136 77.2787 151.319 70.5559Z" fill="#86EFD6"/>
          </svg>

          {/* The zigzag background image on the left */}
          <svg width="89" height="108" viewBox="0 0 89 108" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M8.78764 38.7266C8.75844 38.6741 8.72438 38.613 8.70391 38.5557C8.259 37.6131 8.40152 36.4921 9.06402 35.6881L35.6135 3.32958C36.5602 2.17271 38.2763 2.00667 39.4331 2.95337C40.5949 3.90881 40.7561 5.61616 39.8094 6.77303L16.8605 34.7451L59.1701 35.0793C60.1015 35.087 60.9646 35.5678 61.4521 36.3608C61.9347 37.1451 61.997 38.1406 61.5909 38.9734L42.7558 77.8864L78.4863 83.8768C79.9659 84.1287 80.9591 85.5214 80.716 86.9961C80.4641 88.4756 79.0714 89.4689 77.5967 89.2257L38.2941 82.638C37.4509 82.501 36.7199 81.9695 36.3325 81.2124C35.9451 80.4552 35.9227 79.5519 36.2958 78.7832L54.8305 40.4611L11.1416 40.1168C10.1519 40.1072 9.25964 39.574 8.78764 38.7266Z" fill="#FFF200"/>
          </svg>

          {/* The pink circle, yellow half circle and blue polka dot */}
          <svg width="292" height="404" viewBox="0 0 292 404" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M280 404C286.627 404 292 398.627 292 392C292 385.373 286.627 380 280 380C273.373 380 268 385.373 268 392C268 398.627 273.373 404 280 404Z" fill="#5BCBA5"/>
            <path d="M213.5 157C256.854 157 292 121.854 292 78.5C292 35.1456 256.854 0 213.5 0C170.146 0 135 35.1456 135 78.5C135 121.854 170.146 157 213.5 157Z" fill="#FF5A81"/>
            <path d="M54.9036 265.611C25.3466 283.502 15.3817 321.416 31.8212 351.416L141.628 284.951C122.673 256.474 84.4606 247.721 54.9036 265.611Z" fill="#FBF12E"/>
          </svg>


          {/* The blue sqiggly line */}
          <svg width="109" height="104" viewBox="0 0 109 104" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M88.1592 10.3138C82.661 -0.895035 67.6195 2.38437 62.1943 12.1097C57.0647 21.2993 63.0596 32.1645 58.4748 41.3905C56.396 45.5788 52.1573 48.5507 47.2776 47.6692C42.5749 46.8383 39.0087 42.7078 35.1955 40.2855C30.6099 37.3804 25.7849 36.4248 20.4249 37.9545C16.9106 38.9588 14.0766 41.2865 12.3467 44.3639C9.28926 49.8192 10.6248 56.8538 11.048 62.6854C11.2822 65.8319 11.4177 68.2026 10.7864 71.0116C10.7092 71.3483 10.613 71.672 10.5168 71.9958C10.7312 71.2798 10.3825 72.2937 10.3606 72.3621C10.2263 72.66 10.0757 72.9607 9.92235 73.2457C9.42409 74.1718 10.3679 72.7005 9.73902 73.5519C8.94882 74.6412 8.19823 75.1737 7.49989 75.5193C3.59642 77.3988 0.107536 75.9887 -3.46479 74.1884C-7.79077 72.0154 -12.0015 69.5316 -17.0438 69.3733C-23.2031 69.1793 -28.5809 72.2809 -31.5585 77.4153C-34.5197 82.5468 -34.1036 89.0266 -32.8838 94.4958C-32.4557 96.4123 -29.8972 97.3019 -28.1329 96.6934C-26.0333 95.9791 -25.3606 94.0041 -25.7888 92.0877C-26.7633 87.6604 -26.9317 81.2515 -22.7689 78.1308C-18.4887 74.9092 -13.3514 77.2996 -9.31124 79.3758C-4.86567 81.6578 -0.428414 83.9897 4.80045 83.6633C8.71325 83.4159 12.7725 81.3642 15.2321 78.421C19.6812 73.1326 18.8156 65.5972 18.3036 59.344C18.0581 56.3289 17.8054 52.9753 18.1469 50.5393C18.3856 48.881 18.5118 48.536 19.106 47.3832C19.1717 47.2749 19.7324 46.419 19.243 47.101C19.4343 46.8419 19.6448 46.5957 19.8579 46.3652C19.9426 46.2699 20.7344 45.5847 20.071 46.1347C20.4259 45.8477 20.8216 45.6022 21.2172 45.3567C21.4601 45.202 21.6346 45.1399 22.4282 44.8589C23.0308 44.6428 23.5487 44.522 24.0884 44.4299C26.1135 44.068 27.8503 44.5803 29.6298 45.4411C33.3573 47.2634 36.3923 50.3523 39.9392 52.4966C48.646 57.7552 59.1467 54.1996 64.3228 45.9725C69.3266 38.0176 66.8914 29.5694 67.4889 20.9432C67.7552 17.0804 69.0502 13.6244 72.5512 11.4577C75.2308 9.80336 79.5278 9.5389 81.0724 12.672C81.9451 14.4316 83.7237 15.5838 85.8234 14.8695C87.582 14.3267 89.0345 12.0892 88.1592 10.3138Z" fill="white"/>
            <path d="M97.1392 14.6224C91.641 3.41362 76.5995 6.69301 71.1743 16.4183C66.0447 25.6079 72.0396 36.4731 67.4548 45.6991C65.376 49.8875 61.1373 52.8594 56.2576 51.9778C51.5549 51.1469 47.9887 47.0164 44.1755 44.5941C39.5899 41.689 34.7649 40.7335 29.4048 42.2631C25.8905 43.2674 23.0565 45.5951 21.3267 48.6725C18.2692 54.1278 19.6047 61.1624 20.028 66.994C20.2622 70.1405 20.3976 72.5113 19.7664 75.3203C19.6892 75.6569 19.593 75.9807 19.4968 76.3044C19.7112 75.5885 19.3625 76.6023 19.3406 76.6708C19.2063 76.9687 19.0557 77.2694 18.9023 77.5543C18.4041 78.4805 19.3478 77.0092 18.719 77.8606C17.9288 78.9498 17.1782 79.4823 16.4799 79.828C12.5764 81.7075 9.08752 80.2973 5.51519 78.4971C1.18921 76.3241 -3.02154 73.8402 -8.06387 73.682C-14.2231 73.4879 -19.6009 76.5896 -22.5785 81.7239C-25.5397 86.8555 -25.1236 93.3353 -23.9038 98.8045C-23.4757 100.721 -20.9172 101.611 -19.1529 101.002C-17.0533 100.288 -16.3807 98.3128 -16.8088 96.3964C-17.7834 91.969 -17.9518 85.5602 -13.7889 82.4395C-9.50871 79.2178 -4.37145 81.6082 -0.331272 83.6844C4.1143 85.9664 8.55157 88.2984 13.7804 87.9719C17.6932 87.7245 21.7524 85.6729 24.2121 82.7297C28.6612 77.4412 27.7956 69.9059 27.2835 63.6527C27.0381 60.6375 26.7854 57.2839 27.1269 54.8479C27.3655 53.1897 27.4918 52.8446 28.086 51.6918C28.1517 51.5836 28.7124 50.7276 28.2229 51.4096C28.4143 51.1505 28.6248 50.9043 28.8379 50.6738C28.9226 50.5785 29.7144 49.8934 29.051 50.4434C29.4059 50.1563 29.8016 49.9109 30.1972 49.6654C30.4401 49.5107 30.6146 49.4486 31.4081 49.1675C32.0108 48.9514 32.5287 48.8307 33.0657 48.7228C35.0908 48.3609 36.8276 48.8732 38.6071 49.734C42.3346 51.5563 45.3696 54.6452 48.9165 56.7895C57.6233 62.0481 68.124 58.4925 73.3028 50.2812C78.3065 42.3262 75.8714 33.878 76.4689 25.2518C76.7352 21.3891 78.0302 17.933 81.5312 15.7664C84.2108 14.112 88.5078 13.8476 90.0524 16.9806C90.925 18.7403 92.7037 19.8924 94.8033 19.1781C96.5456 18.6381 97.9981 16.4006 97.1392 14.6224Z" fill="#00377C"/>
          </svg>

      </div>

      {/* This section holds the multiple singleReviews components */}
        <div className="review__inner">
          <h1 className="review__heading">Testimonials</h1>
            <div className="review__info">
              <div className="review__grid">  
                {ReviewsData.slice(0,1).map((item) => (
                  <div>
                    <SingleReview
                      singleReviewLink={item.singleReviewLink}
                      singleReviewLinkText={item.singleReviewLinkText} 
                      reviewText={item.reviewText}
                      reviewAuthor={item.reviewAuthor}
                    />
                  </div>
                ))}

                <div className="review__quote-left">
                  <svg width="263" height="216" viewBox="0 0 263 216" fill="blue" xmlns="http://www.w3.org/2000/svg">
                    <path d="M57.855 0.117188C73.9155 0.117188 87.532 6.0526 98.7045 17.9234C109.877 30.4925 115.463 47.2513 115.463 68.1998C115.463 98.226 110.924 122.666 101.847 141.52C92.7691 161.072 82.644 176.434 71.4715 187.606C58.9024 200.175 43.8893 209.602 26.4322 215.887L15.958 199.128C27.8288 193.542 38.303 186.908 47.3807 179.227C55.0618 172.244 62.3938 163.865 69.3766 154.089C75.6612 145.011 78.8035 133.838 78.8035 120.571C78.8035 115.683 78.1052 111.843 76.7086 109.049C76.0103 106.955 74.9629 105.558 73.5663 104.86C72.1698 105.558 70.0749 106.256 67.2818 106.955C65.1869 107.653 63.0921 108.351 60.9972 109.049C58.2041 109.748 55.411 110.097 52.6178 110.097C36.5573 110.097 23.9882 105.209 14.9105 95.4329C5.13456 86.3552 0.246582 73.7861 0.246582 57.7256C0.246582 41.6651 5.83285 28.0485 17.0054 16.876C28.1779 5.70347 41.7944 0.117188 57.855 0.117188ZM204.494 0.117188C220.555 0.117188 234.172 6.0526 245.344 17.9234C256.517 30.4925 262.103 47.2513 262.103 68.1998C262.103 98.226 257.564 122.666 248.486 141.52C239.409 161.072 229.284 176.434 218.111 187.606C205.542 200.175 190.529 209.602 173.072 215.887L162.597 199.128C174.468 193.542 184.943 186.908 194.02 179.227C201.701 172.244 209.033 163.865 216.016 154.089C222.301 145.011 225.443 133.838 225.443 120.571C225.443 115.683 224.745 111.843 223.348 109.049C222.65 106.955 221.602 105.558 220.206 104.86C218.809 105.558 216.714 106.256 213.921 106.955C211.826 107.653 209.732 108.351 207.637 109.049C204.844 109.748 202.05 110.097 199.257 110.097C183.197 110.097 170.628 105.209 161.55 95.4329C151.774 86.3552 146.886 73.7861 146.886 57.7256C146.886 41.6651 152.472 28.0485 163.645 16.876C174.817 5.70347 188.434 0.117188 204.494 0.117188Z" fill="#5760ED" fillOpacity="0.5"/>
                  </svg>

                </div>

                {ReviewsData.slice(1, 12).map((item, index) => (
                  <>
                    { (index === 7 || index === 9) && <div className="review__empty_holder" />}
                    <div>
                      <SingleReview
                        singleReviewLink={item.singleReviewLink}
                        singleReviewLinkText={item.singleReviewLinkText}
                        reviewText={item.reviewText}
                        reviewAuthor={item.reviewAuthor}
                      />
                    </div>
                  </>
                ))}

                </div>
                </div>
            </div>

            <div className="review__hear-from-our-partners">
                <p><span>Hear</span> from</p>
                <p>our partners</p>
            </div>

            <div className="review__inner review__last_inner">
              <div className="review__grid">
                {ReviewsData.slice(12).map((item) => (
                  <div>
                    <SingleReview
                      singleReviewLink={item.singleReviewLink}
                      singleReviewLinkText={item.singleReviewLinkText} 
                      reviewText={item.reviewText}
                      reviewAuthor={item.reviewAuthor}
                    />
                  </div>
                ))}
                </div> 
            </div>

            <Contact />
            <ScrollButton />
        </section>
    )
}

export default Review
