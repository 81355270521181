import { useCallback, useEffect, useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import { Alert, Button, Container, Grid, TextField } from "@mui/material";
import { CSSTransition } from "react-transition-group";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
import "./forgotPassword.scss";

const validateEmail = (email) => {
  const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regexp.test(email);
};

export const ForgotPassword = () => {
  const [user, setUser] = useState({
    email: "",
  });
  const [error, setError] = useState({
    status: false,
    message: "",
  });
  const [success, setSuccess] = useState(false);
  const [captcha, setCaptcha] = useState("");
  const [erCaptcha, setErCaptcha] = useState(false);
  const reCaptchaRef = useRef();
  const handleChange = useCallback(
    (e) => {
      setUser({ ...user, [e.target.name]: e.target.value });
    },
    [setUser, user],
  );
  const source = axios.CancelToken.source();
  const handleSubmit = (email) => {
    setError({
      status: false,
      message: "",
    });
    if (!validateEmail(email)) {
      setError({
        status: true,
        message: "Email is not valid!",
      });
      return;
    }
    if (captcha.length === 0) {
      setError({
        status: true,
        message: "ReCaptcha is required",
      });
      return;
    }
    axios
      .post(
        `/api/v3/user/forgot-password?email=${email}`,
      )
      .then((response) => {
        if (response.status === 200) {
          setSuccess(true);
        }
      })
      .catch(() => {
        setError({
          status: true,
          message: "Something went wrong",
        });
      });
  };

  useEffect(() => {
    return () => {
      source.cancel();
    };
  }, [source]);

  const handleCaptchaChange = useCallback(
    (e) => {
      setCaptcha(e);
    },
    [setCaptcha],
  );

  return (
    <Container className={"center-block"} maxWidth="sm">
      <h1 className="sign-in-title">Forgot Password</h1>
      {success ? (
        <CSSTransition
          in={success}
          timeout={500}
          classNames="my-node"
          unmountOnExit
        >
          <div className="success__message">
            <p>
              Check your email inbox <NavLink to="/login">Sign In</NavLink>
              <br />
              <small>Please allow up to 5 minutes for the email to come through before attempting to reset your password
                again.</small>
            </p>
          </div>
        </CSSTransition>
      ) : (
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit(user.email);
            e.target.reset();
          }}
        >
          <CSSTransition
            in={error.status}
            timeout={500}
            classNames="my-node"
            unmountOnExit
          >
            <Alert severity="error">{error.message}</Alert>
          </CSSTransition>
          <Grid item xs={12}>
            <TextField
              required
              id="standard-required"
              label="Email"
              type="text"
              variant="outlined"
              name="email"
              defaultValue={user.email}
              onChange={handleChange}
            />
          </Grid>
          <div
            className="form-group"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <ReCAPTCHA
              ref={reCaptchaRef}
              sitekey="6Lc6O9AUAAAAAF6myYURrvZwW43E4ShPeKOVKCHe"
              onChange={handleCaptchaChange}
            />
            {erCaptcha && <Alert severity="error">ReCaptcha is required</Alert>}
          </div>
          <Grid
            item
            container
            xs={12}
            direction="row"
            justify="center"
            alignItems="center"
          >
            <Button
              size="large"
              variant="contained"
              type="submit"
              color="primary"
            >
              Reset Password
            </Button>
          </Grid>
        </form>
      )}
    </Container>
  );
};
