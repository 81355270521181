import React from 'react';
import './BrandsCommission.scss';

const BrandsCommission = ({data}) => {
  return (
    <section className='lpcasino__brandcommission'>
        <div className="section-inner">
            <div className="section-heading">
                <h3>{data.title.split(' ')[0]}</h3>
                <h3>{data.title.split(' ')[1]}</h3>
            </div>
            
            <div className="lpcasino__brand_commission_single_container">
                {
                    data.items.map((item) => {
                        return (
                            <div className="lpcasino__brand_commission_single" key={item.id}>
                                <div>
                                    <span>{item.number}</span>
                                </div>
                                <p>{item.text}</p>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    </section>
  )
}

export default BrandsCommission