import { useState, useCallback, useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";
import { toastApiError } from "../../utils/toastApiError";

const initialFormValues = {
  company: "",
  name: "",
  email: "",
  message: "",
};

const Contact = () => {
  const [form, setForm] = useState({ ...initialFormValues });
  const [captcha, setCaptcha] = useState(false);
  const [captchaError, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  const reCaptchaRef = useRef(null);

  const formRef = useRef(null);

  const handleChange = useCallback(
    (e) => {
      setForm({
        ...form,
        [e.target.name]: e.target.value,
      });
    },
    [setForm, form],
  );

  const handleCaptchaChange = useCallback(
    (e) => {
      setCaptcha(e);
    },
    [setCaptcha],
  );

  const handleCaptchaError = () => setError(true);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (captcha) {
      try {
        await axios.post(`/api/v3/messages/contact-us`, {
          ...form,
          gRecaptchaResponse: captcha,
        });
        setSuccess(true);
        setForm({ ...initialFormValues });
      } catch (err) {
        setSuccess(false);
        toastApiError(err);
      } finally {
        reCaptchaRef.current?.reset();

      }
    }
  };

  return (
    <div id="contact" className="content home-contact contact">
      <div className="innerWrap">
        <h2 className="block__title contact__title">Get in touch</h2>
        {success && (
          <p
            style={{
              textAlign: "center",
              color: "white",
            }}
          >
            Your message has been sent, we’ll get back to you within 24 hours
          </p>
        )}
        <span className="contact__time">Have a question?</span>
        <p className="contact__text">
          You can reach our dedicated affiliate team via our live chat system or
          through the contact form below. Alternatively, please email
          affiliates@winningpartner.com
        </p>
        <form onSubmit={handleSubmit} id={form} ref={formRef}>
          <div className="form__field contact__form_field">
            <input
              type="text"
              name="company"
              value={form.company}
              onChange={handleChange}
              id="company"
              placeholder="Company"
              required
            ></input>
          </div>
          <div className="form__field contact__form_field">
            <input
              type="text"
              id="name"
              value={form.name}
              onChange={handleChange}
              name="name"
              placeholder="Name"
              required
            ></input>
          </div>
          <div className="form__field contact__form_field">
            <input
              type="email"
              name="email"
              value={form.email}
              onChange={handleChange}
              id="email"
              placeholder="Email"
              required
            ></input>
          </div>
          <div className="form__field contact__form_field">
            <textarea
              name="message"
              onChange={handleChange}
              value={form.message}
              id="message"
              placeholder="Message"
            ></textarea>
          </div>

          <div className="contact__form_recaptcha">
            <ReCAPTCHA
              ref={reCaptchaRef}
              onErrored={handleCaptchaError}
              sitekey="6Lc6O9AUAAAAAF6myYURrvZwW43E4ShPeKOVKCHe"
              onChange={handleCaptchaChange}
            />
          </div>
          <div className="contact__form_btn">
            <input type="submit" value="Send message"></input>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Contact;
