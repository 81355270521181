import { useLocation, Redirect } from "react-router-dom";

const Referral = () => {
  const location = useLocation();

  const referral_code = location.pathname.split("/")[2];

  document.cookie = `referral_code=${referral_code}`;

  const cookie = document.cookie;

  // console.log(cookie.split(";")[0].split("="));
  return <Redirect to="/signup" />;
};

export default Referral;
