import React from 'react';
import './BrandsProvider.scss';

const BrandsProvider = ({data}) => {
  return (
    <section className="lpcasino__brands_provider">
        <div className="section-inner">
            <div className="lpcasino__brands_provider_container">
                <div className="section-heading">
                    <h3>{data.title.split(' ')[0]}</h3>
                    <h3>{data.title.split(' ')[1]}</h3>
                </div>

                <p className="lpcasino__brands_provider_paragraph">{data.paragraph}</p>.

                <div className="lpcasino__brands_provider_img_box">
                    {
                        data.images.map((item, index) => {
                            return (
                                <img key={index} src={item} />
                            )
                        })
                    }
                </div>
            </div>
        </div>
    </section>
  )
}

export default BrandsProvider