import React from 'react';
import './BrandsBlocks.scss';

const BrandsBlocks = ({ data }) => {
  return (
    <section className="lpcasino__brand_blocks">
        {
            data.map((item) => {
                return (
                    <div className="lpcasino__brand_blocks_single" key={item.id}>
                        <h4>{item.title}</h4>
                        <h3>{item.text}</h3>
                        <img src={item.img} alt="" />
                    </div>
                )
            })
        }
    </section>
  )
}

export default BrandsBlocks