import { useEffect, useState } from "react";
import axios from "axios";
import "./Terms.scss";
import ScrollButton from "../../components/ScrollButton/ScrollButton";
// import { toastApiError } from "../../utils/toastApiError";


const Terms = () => {
  const [data, setData] = useState(null);

  const fetchTermsData = async () => {
    try {
      const response = await axios.get(
        //TODO make this work
        `/api/v2/article?key=terms`,
      );
      if (response) {
        setData(response.data.data.attributes.value);
      }
    } catch (err) {
      //TODO uncomment when api call starts work (with import above)
      //toastApiError(err);
    }
  };

  useEffect(() => {
    fetchTermsData();
  }, []);

  return (
    <>
      <section id="terms-top" className="terms_section">
        <div className="innerWrap">
          <h2>WinningPartner Terms and Conditions</h2>
        </div>
      </section>

      <section id="main" className="content terms_section__container">
        <div className="innerWrap terms-wrap terms_section__box">
          <p>The following are the terms and conditions of the Affiliate Program between WinningPartner and
            the party whose legal name is found at the end of this Agreement (hereafter, "you", "your",
            "affiliate").</p>

          <p>WinningPartner is the Group company that owns and operates the Affiliate Program whereby
            third-party commercial partners may refer individuals to the appropriate entity in the Group
            for purposes of such individuals becoming a Group Customer subject to the following terms and
            conditions:</p>

          <p>Definitions "ASA" means the Advertising Standards Agency of the United Kingdom or
            any successor government agency; "Net Revenue" means the amount of bets placed by Customers
            in the relevant calendar month less: (i) the winnings; (ii) bonuses; (iii)
            Chargebacks; (iv) Gambling Taxes; and (v) any other revenue returns, credits,
            compensations or refunds given to Customers; "Chargeback" or "Credit" is a credit card
            transaction which is not collectable by a credit card company as a result of Customers
            non-payment or fraudulent credit card use, or other Customer payment transaction which is
            revoked and for which a credit is given; "CMA" means the Competition and Markets Authority of
            the United Kingdom, or any successor government agency; "Consumer Protection Legislation" means the
            legislative and statutory requirements applicable to the conduct of arrangements with the Customers and
            potential customers, including, (without limitation), the UK Consumer Rights Act 2015 and the Consumer
            Protection from Unfair Trading Regulations 2008), each as amended from time to time, and, additionally,
            the content of any statutory undertaking given by participants in the Groups gaming industry from time to
            time to the CMA; "Customer" is an individual that: (i) has opened a new account with the Group in respect
            of any Group Property; (ii) has not previously opened an account with any Group Property; (iii) has had
            their
            account registration details adequately validated and approved by the affiliate, including (without
            limitation) that
            the individual is confirmed to be 18 years of age or above; (iv) places stakes, or plays, (as relevant) with
            deposited money on the relevant Group Property or App; and (v) is qualified and authorized to be a customer
            of
            and access and use the relevant Group Property in accordance with the terms and conditions of use of such
            Property
            and in compliance with all applicable laws, rules and regulations; "Data-Based Marketing" means as defined
            in clause</p>

          <p>2.1; "Data Protection Legislation" means all applicable data protection, privacy and electronic marketing
            legislation,
            including any national legislation implementing Directive 2002/58/EC, GDPR (and any related national
            legislation such as the Data Protection
            Act 2018 in Ireland and UK), any equivalent, replacement or repealing legislation, and any codes of practice
            issued by a competent data protection regulator governing the Group relating to the same at any time;
            "Deposits"
            are the funds transferred by Customers to their Customer accounts at the relevant Group Properties;
            "Gambling Commission" means the Gambling
            Commission of the United Kingdom, or any successor government agency; "Gambling Taxes" means any tax, duty
            or
            levy, including VAT or equivalent, payable by the Group to any authority in any jurisdiction in respect of
            bets received from Customers, whether charged on turnover, gross profits and/or any other profit
            calculation,
            although not including corporate income tax or equivalent; "GDPR" means Regulation (EU) 2016/679, known as
            the
            General Data Protection Regulation; "Licensing Policy" means the licensing objectives set out in Section 1
            of
            the UK Gambling Act 2005 and any successor legislation, being: (i) preventing gambling from being a source
            of
            crime and/or disorder, being associated with crime or disorder and/or being used to support crime; (ii)
            ensuring that gambling is conducted in a fair and open way; and (iii) protecting children and other
            vulnerable persons from being harmed and/or exploited by gambling and, additionally, that Group
            shall fully comply with the Gambling Commissions regulations referred to as the Licence Conditions
            and Codes of Practice; "Property(ies)" are the websites, brands and Apps offered and / or promoted
            by the Group and/or any designated third party appointed by the Group from time to time (e.g.,
            333Casino, DiceDen, SpinSlots, MiamiJackpots, and all other related brands); "Website-Based Marketing"
            means as defined in clause 2. </p>

          <h6>1. Modifying This Agreement</h6>

          <p><b>1.</b> WinningPartner may modify any of the terms and conditions contained in this Agreement,
            at any time and at its sole discretion, by sending you a notice identifying the changes. Modifications
            may include, but are not limited to, changes in the payment schedule or other rules.
            IF ANY CHANGE IN THE AGREEMENT IS UNACCEPTABLE TO YOU, YOU MAY TERMINATE THIS AGREEMENT BY NOTIFYING
            WinningPartner IN WRITING. CONTINUED PARTICIPATION IN THIS PROGRAM FOLLOWING OUR MAKING A
            CHANGE WILL CONSTITUTE BINDING ACCEPTANCE OF THE CHANGE.</p>

          <h6><b>2.</b>Your Rights and Responsibilities</h6>
          <h6><b>2.1</b> Promoting the Group</h6>
          <p><b>2.1.1.</b> By agreeing to participate in the Group Affiliate Program,
            you agree to do one or both of the following: (a) create a unique link(s)
            from your website from time to time as provided by this clause to any Group Property
            and/or or App (Website-Based Marketing); and/or (b) promote one or more Group Propertys
            products and services to potential Customers via email, mail via a postal or courier service,
            push notifications, SMS, WhatsApp and/or any other medium capable of use for marketing now
            available or hereafter known ("Data-Based Marketing").</p>

          <p><b>2.1.2</b> Where you act on behalf of one or more Group Properties as envisaged by this Agreement,
            you shall be provided from time to time with promotional material for use with Website-Based
            Marketing and/or Data-Based Marketing, as appropriate for the service(s) you have agreed to
            provide to us. You acknowledge and accept that such promotional material often can contain
            content whose purpose is to satisfy the Groups regulatory obligations (to which such regulation
            the affiliate also accedes and becomes contractually bound to by virtue of this Agreement).
            You agree that in no case shall you amend, alter or otherwise change any content of such promotional
            material without prior approval from authorized Group staff. Any such alteration may result in a material
            breach of this Agreement that, without limitation, may result in non payment or withholding of any Referral
            Fee payment that otherwise would be due and owing to you.</p>

          <p><b>2.1.3</b> You shall not make any claims, representations, or warranties in connection with the Group
            Properties and you shall have no authority to, and will not, bind or seek to WinningPartner or any entity
            within the Group to any obligation without first obtaining written permission from the appropriate member of
            the Group. You further agree to comply with, among other applicable provisions of this Agreement, clauses
            2.3.1. below.</p>
          <p><b>2.1.4</b> Where an affiliate engages in Data-Based Marketing, you agree to comply with, among other
            applicable provisions of this Agreement, clauses 2.3.2. - 2.3.9. Below.</p>

          <h6>2.2 Agreement</h6>
          <p><b>2.2.1.</b> You agree to all of the terms set forth herein and to positively endorse the Group at all
            times and maintain a site content that is free of pornography or other sex acts, promotion of violence,
            promotion of discrimination based on race, sex, religion, nationality, disability, sexual orientation or
            age, promotion of illegal activities, or violation of intellectual property rights.</p>
          <p><b>2.2.2.</b> You must provide true and complete information to the Group at all times; including but not
            limited to, your identity, contact information, payment instructions, and any other information that the
            Group may reasonably request from time to time.</p>
          <p><b>2.2.3.</b> WinningPartner grants you the non-exclusive right to direct Customers to the Group Properties
            and Apps, in accordance with the terms and conditions of this Agreement. You shall have no claims to
            Referral Fees or other compensation for Customers secured or partially secured by or through persons or
            entities other than you. You also understand that the Group may at any time (directly or indirectly) solicit
            potential Customer referrals on terms that may differ from those attained here, or operate or contract with
            third parties that are similar to, or compete with you.</p>
          <p><b>2.2.4.</b> Affiliates must ensure that they do not place digital advertisements on websites or elsewhere
            providing unauthorized access to copyrighted content.</p>
          <p><b>2.3.</b> Affiliates responsibility and obligations for its website and other marketing materials and
            practices</p>
          <p><b>2.3.1.</b> Website-Based Marketing affiliates: You shall be solely responsible for the development,
            operation and maintenance of your promotional materials, including website site and for all promotional
            materials including those that may appear on your website. For example, you will be solely responsible for
            ensuring that materials posted on your site are not libellous or otherwise illegal. WinningPartner disclaims
            all liability for any and all content you produce as part of your Website-Based Marketing on behalf of the
            Properties. Further, you will indemnify and hold WinningPartner, and any entity or individual employee or
            consultant in its corporate group, harmless from all claims, damages and expenses (including, but not
            limited to, attorneys' fees) relating to the development, operation, maintenance and contents of your
            Website-Based Marketing and your performance of this Agreement.</p>
          <p><b>2.3.2.</b> (Clauses 2.3.2. through 2.3.9. pertain to Data-Based Marketing affiliates) You agree to
            refrain from utilizing 'spam' for marketing our products and services (all email, SMS and all other
            electronic marketing must be based on opt-in rules compliant with European Union, Irish, UK and English law
            from time to time).</p>
          <p><b>2.3.3.</b> The affiliate represents and warrants that it is a data controller for purposes of the GDPR
            and that WinningPartner has no nexus to the affiliates customers unless and until such time as such
            individuals become Customers of the appropriate entity(ies) in the Group as contemplated by this Agreement.
          </p>
          <p><b>2.3.4.</b> The affiliate shall at all times comply with the Data Protection Legislation including,
            without limitation, ensuring that affiliate customers' personal data: (i) is collected fairly, lawfully and
            transparently; (ii) is processed in accordance a lawful condition as set out in the GDPR; and (iii) is
            protected from loss, theft, accidental destruction or unauthorized access by implementing appropriate
            technical and organization measures in respect of such personal data.</p>
          <p><b>2.3.5.</b> The Affiliate warrants that all direct marketing pertaining to the Group sent to the
            customers for whom it is data controller for purposes of the GDPR shall only be done so where such customers
            have given valid consent to receive such marketing communication as required by the Data Protection
            Legislation. Valid consent shall include data subjects opt-in to such marketing and data subjects being
            informed that they shall receive marketing relating to the Group (identified either specifically or, at the
            least, by its industry or other acceptable means). 2.3.6. The affiliate warrants that all direct marketing
            pertaining to the Group sent to its customers shall include an opportunity for such customers to opt-out of
            all future direct marketing engaged in by the affiliate.</p>
          <p><b>2.3.7.</b> The affiliate warrants that it shall not send direct marketing to any of its customers who
            have not provided valid consent to receive such affiliates marketing or who have unsubscribed from its
            direct marketing.</p>
          <p><b>2.3.8.</b> Without prejudice to the warranties given in this clause 2.3, the affiliate shall not send
            any direct marketing or other promotional materials pertaining to the Group to any of its customers where
            the affiliate has received instructions from the Group not to send such direct marketing whether pursuant to
            any excluded customers list as maintained by the Group or otherwise. The affiliate shall confirm its
            compliance with any instructions pursuant to this clause 2.3.8. by email to affiliates@winningpartner.com no
            less than 48 hours after receipt of an instruction from WinningPartner or on its behalf from any member of
            the Group.</p>
          <p><b>2.3.9.</b> The affiliate agrees only to market to potential Customers who do not appear on any
            suppression list or self-exclude list provided to the affiliate, from time to time, on behalf of the Group.
            For avoidance of doubt, suppression lists are distributed through third party list management companies
            unrelated to the Group. Only affiliates or potential affiliates who integrate at all times during the
            existence of this Agreement with a third party list management company(ies) as communicated to the affiliate
            by the Group from time to time (currently the Groups list management is provided by Optizmo Technologies,
            LLC) will be allowed to participate in the Group Affiliate Program and engage in Data-Based Marketing on our
            behalf.</p>
          <p><b>2.3.10.</b> When marketing or promoting the Winning Partner Sites to people located in Great Britain,
            all marketing materials and communications relating to the Licensed Sites as well as the websites and
            applications on which the Licensed Sites are marketed or promoted on, comply with all applicable laws,
            regulations and codes of practices including without limitation the Gambling Act 2005, especially Section
            330.</p>
          <p><b>2.3.11.</b> The affiliate shall notify WinningPartner via the Group immediately in the event that any of
            its customers makes a complaint to the affiliate, or where any data protection regulator contacts the
            affiliate in a manner that pertains to the Group and in respect of direct marketing or the affiliate's
            processing of its customers personal data.</p>
          <p><b>2.3.12.</b> WinningPartner via the Group may, from time to time, request that the affiliate provide
            evidence of its compliance with this clause 2.3 and affiliate shall provide such evidence as the Group may
            reasonably request within seven days of receipt of such request.</p>
          <p><b>2.3.13.</b> The affiliate shall ensure that all its own data processors (as defined by the GDPR) acting
            on its behalf pursuant to this Agreement are bound by contractual terms no less onerous than the standards
            prescribed by the Data Protection Legislation.</p>
          <p><b>2.3.14.</b> The affiliate shall provide the Group with all such assistance as necessary in respect of
            data breaches, claims and requests for information made against the Group in respect of any communications
            to its customers sent by the affiliate under this Agreement, in particular, any investigations made by a
            data protection regulator, the ASA and/or the CMA.</p>
          <p><b>2.3.15.</b> The affiliate shall ensure that any communications sent by it or any of its processors are
            duly tagged to allow tracking in the event that they are forwarded to the appropriate member of the Group as
            part of a complaint.</p>
          <p><b>2.3.16.</b> The affiliate agrees to indemnify Group and defend Group at its own expense against all
            costs, claims, fines, group actions, damages and expenses incurred by Group or for which Group may become
            liable due to any failure by the affiliate or its employees, consultants, agents, subcontractors or data
            processors to comply with any of its obligations under this clause 2.3 or any failure to comply with data
            protection legislation . Nothing in this Agreement shall limit the affiliates liability under this clause
            2.3.</p>
          <p><b>2.3.17.</b> the affiliate agrees: -</p>
          <p><b>2.3.18.1</b> to have due regard for, and act at all times in a manner consistent with, the Licensing
            Policy, as though the affiliate itself were a licensee of the Gambling Commission itself, and agrees to
            demonstrate its consideration of, and adherence to, the Licensing Policy in all of its activities as an
            affiliate; -</p>
          <p><b>2.3.18.2</b> to comply with the Consumer Protection Legislation, to observe and comply with all
            directions and instructions issued by the Group concerning compliance with the Consumer Protection
            Legislation and to ensure that it shall not, in its activities undertaken in furtherance of this Agreement,
            prejudice, or otherwise interfere with, the Groups (or any member thereofs) compliance with the Consumer
            Protection Legislation. In particular, the affiliate shall not alter the appearance, design and content of
            Group's approved banners and text links and/or promotional messaging, or complete any action that will have
            the effect of: (i) altering or removing any promotional terms and conditions which the Group includes on any
            banners and text links and/or promotional messaging; or (ii) affecting the means by which a potential
            customer may access such promotional terms and conditions from the banners and text links and/or promotional
            messaging, including the expectation that such promotional terms and conditions must be accessible within a
            single 'click' of the Groups approved banners and text links and/or promotional messaging or on any linked
            landing and/or sign-up webpages (or technological equivalent location in non-browser based platforms and
            technologies such as mobile phone applications). -</p>
          <p><b>2.3.18.3</b> to provide such information to the Group as it may reasonably require in order to enable it
            to comply with its members information reporting and other obligations to the Gambling Commission, CMA
            and/or ASA.</p>
          <p><b>2.3.19.</b> The affiliate agrees to abide by and comply with the ASAsCAP Broadcast Code and
            Non-Broadcast Code and published rulings, as promulgated from time to time.</p>
          <p><b>2.3.20.</b> The affiliate agrees to use our marketing creatives and you will not alter the material in
            any way. Prior written approval is required for the affiliate to vary the Groups marketing creative and the
            affiliate shall further ensure that such variation complies with the aforementioned CAP codes and the Groups
            own internal standards as made known to the affiliate from time to time. 2.3.21. Non-compliance on the part
            of the affiliate in particular with any provision(s) of this section 2.3 mentioned above may lead to the
            issuing of warnings which ultimately may result in removal from the Group Affiliate Program of any
            affiliate, including withholding of any Referral Fees that may otherwise be due or come due to such
            affiliate.</p>
          <p><b>2.3.22.</b> The affiliate shall not knowingly benefit from known or suspected traffic not generated
            using accepted Internet marketing practices whether or not it causes the Group harm. Should fraudulent, spam
            or unethical activity, knowingly or otherwise, arise from a person directed to a Group brand website via the
            affiliates link or other actions taken in furtherance of this Agreement, WinningPartner retains the right to
            withhold any Referral Fees to be paid to the affiliate at any time and the appropriate member of the Group
            may seek damages and indemnification, as appropriate. The Groups decision in this regard will be final.
            WinningPartner further reserves the right to retain all amounts otherwise due to the affiliate under this
            Agreement if it has reasonable cause to believe that such traffic has been caused with the affiliates
            knowledge. For Customers that are found to be engaging in or who have engaged in fraudulent activity or
            otherwise in breach of a Propertys terms and conditions with that Customer, WinningPartner reserves the
            right to deduct any Referral Fees earned by the affiliate from the activities of these Customers even where
            these Referral Fees had been previously paid to the affiliate. If the affiliate tag is not used in exactly
            the way it is given by the system, and altered in any way, WinningPartner reserves the right to deduct the
            Referral Fees earned by the affiliate from these altered tags, even if these Referral Fees had been
            previously paid out.</p>
          <p><b>2.3.23.</b> The affiliate acknowledges and agrees that no commission shall be payable on any
            incentivized traffic. Incentivized traffic refers to any traffic that has been generated by offering an
            incentive, including but not limited to, offering rewards for sign-ups, downloads, or clicks. WinningPartner
            reserves the right to withhold payment of any commission where the traffic is found to be incentivized, and
            the affiliate agrees to indemnify and hold harmless WinningPartner for any losses or damages arising from
            incentivized traffic.</p>
          <p><b>2.4</b> License to Use Marks WinningPartner, on behalf of, and under appropriate licence from, the
            Group, hereby grants you a non-exclusive, non-transferable license, during the term of this Agreement, to
            use the trademarks service marks and other intellectual property relating to the Properties provided by the
            Group solely in connection with the promotion of the Group (the Intellectual Property). This license cannot
            be sub-licensed, assigned or otherwise transferred by you. Your right to use the Intellectual Property is
            limited to, and arises only out of, this license to use the Group material. You shall not assert the
            invalidity, unenforceability, or contest the ownership of the Intellectual Property in any action or
            proceeding of whatever kind or nature, and shall not take any action that may prejudice our rights in the
            Intellectual Property, render the same generic, or otherwise weaken their validity or diminish their
            associated goodwill. Further, you agree to keep information about the Group current on your site.</p>
          <p><b>2.5</b> Confidential Information During the term of this Agreement, you may be entrusted with
            confidential information relating to the business, operations or underlying technology of the Group and/or
            the Group Affiliate Program. You agree to avoid any disclosure to third persons or outside parties or
            unauthorized use of the confidential information unless you have our prior written consent and that you will
            use the confidential information only for purposes necessary to further the purposes of this Agreement. Your
            obligations with respect to confidential information shall survive the termination of this Agreement.</p>

          <h6>3.Fee Schedule and Payment</h6>
          <p><b>3.1</b> Once you become an affiliate, you will have access to all marketing material containing a unique
            affiliate code. When your customer or contact links to or otherwise access a Property, the individual
            receives a cookie on his or computer or mobile device with your code. We operate a ‘last click wins policy’
            between affiliate partners. The Group is not responsible for any problems or errors with the process of
            tagging an account with your code.</p>
          <p><b>3.2.</b> Deposit of payment or acceptance of payment transfer will be deemed full and final settlement
            of all Referral Fees due (and, for Ongoing Referral Fee Customers, Referral Fees for the month) indicated
            unless you sent us notice of disagreement with the amount payable within twenty (20) days from the date
            payment is made. The failure to timely send such notice irrevocably waives your right to contest any fees
            due for the relevant time period and any prior time period.</p>

          <h6>4.Term and Termination</h6>
          <p><b>4.1.</b> The term of this Agreement will begin following the confirmation of an agreed insertion order
            and will be continuous unless and until either party notifies the other in writing that it wishes to
            terminate the Agreement. Termination may be accomplished by either you orWinningPartner or the Group on
            behalf of WinningPartner for any reason or without reason at any time by providing notice to the other
            party. For purposes of notification, delivery via email is considered a written and immediate form of
            notification after a time period of three hours where there is no bounceback or other notification of
            non-delivery, delivery failure or the like; email notification must be acknowledged as having been received
            by the receiving party of such notification.</p>
          <p><b>4.2.</b> If termination is by "WinningPartner or the Group" and is not caused by violation on your part
            of this Agreement, you will be entitled to unpaid referral fees, if any earned by you on or prior to the
            date of termination and as subject to modification described above.</p>
          <p><b>4.3.</b> If termination is initiated by you or is by "WinningPartner or the Group" as a result of a
            violation of this Agreement, you will not be entitled to unpaid Referral Fees, if any, earned by you on or
            prior to the date of termination. Nor will you be entitled to Referral Fees with respect to playing activity
            of a Customer occurring after the termination date.</p>
          <p><b>4.4.</b> Immediately upon termination of this Agreement for any reason, you must remove all Group
            banners/icons from your site and disable any link from your site to the Group or any Property thereof.</p>
          <p><b>4.5.</b> All rights and licenses given to you in this Agreement shall immediately terminate upon
            termination of this Agreement for any reason.</p>
          <p><b>4.6.</b> Upon termination of this Agreement for any reason, WinningPartner may withhold your final
            payment for a reasonable amount of time to ensure that a correct final amount is duly calculated.</p>
          <p><b>4.7.</b> Upon termination of this Agreement for any reason, you will return to the Group any
            confidential information, and all copies of it in your possession, custody and control, and will cease all
            uses of any trade names, trademarks, service marks, logos and other designation or Intellectual Property of
            the Group.</p>
          <p><b>4.8.</b> Before or after termination of this Agreement, WinningPartner, or a member of the Group on its
            behalf, reserves the right to modify this Agreement or the fee schedule (SCHEDULE A) as described above,
            with such modification effective immediately.</p>
          <p><b>4.9.</b> Neither You, nor Your friends, employees, servants, agents, advisors or relatives are eligible
            to become Customers through Your Trackers, and should You or they do so, You will not be eligible to receive
            the relevant Affiliate Fees.</p>
          <p><b>4.10.</b> In accordance with section 330 of the Gambling Act of 2005, it is unlawful to advertise
            gambling on websites which do not carry the correct permits/licenses to offer such gambling. In order to
            uphold the objectives of the Gambling Act of 2005, we have a zero-tolerance stance to working with any
            affiliate who undertakes such activity and if we discover such this agreement will be terminated and no
            monies owed will be paid and we will report you to the relevant authorities. For the avoidance of doubt,
            even if our Brands are not being advertised on the same websites that are advertising non-licensed brands,
            our policy is not to work with affiliates/marketing partners even on their websites which only offer
            licensed brands.</p>

          <h6>5.Relationship of Parties</h6>
          <p><b>5.1.</b> Both you andWinningPartner are independent contractors and nothing in this Agreement will
            create any partnership, joint venture, franchise, sales representative, consultancy or employment
            relationship between the parties. You will have no authority to make or accept any offers or representations
            with any third parties on our behalf. You will not make any statement, whether on your site or otherwise,
            that contradicts anything in this paragraph.</p>

          <h6>6.Indemnity</h6>
          <p><b>6.1.</b> You shall defend, indemnify, and holdWinningPartner, and, where appropriate, any or all members
            of the Group, their respective directors, officers, employees, contractors, agents and representatives
            harmless from and against any and all liabilities, losses, damages, and costs, including reasonable attorney
            fees, resulting from, arising out of, or in any way connected with (a) any breach by you of any warranty,
            representation, or agreement; (b) the performance of your duties and obligations under this Agreement; (c)
            your negligence; or (d) any injury caused directly or indirectly by your negligent or intentional acts or
            omissions, or the unauthorized use of our banners and links or this Group Affiliate Program.</p>

          <h6>7.Disclaimers</h6>
          <p><b>7.1.</b> WinningPartner makes no express or implied warranties or representations with respect to
            itself, the Group Affiliates Program or Referral Fee payment arrangements (including, without limitation,
            their functionality, warranties of fitness, ability, legality, non-infringement, or any implied warranties
            arising out of a course of performance, dealing or trade usage). In addition,WinningPartnermakes no
            representation that the operation of our Properties and Apps will be uninterrupted or error-free, and
            neither WinningPartner, nor any member of the Group shall be liable for the consequences of any interruption
            errors.</p>

          <h6>8.Operating Agreement and Disclosure</h6>
          <p><b>8.1.</b> Limitations of Liability</p>
          <p><b>8.1.1.</b> WinningPartner SHALL NOT BE LIABLE TO THE AFFILIATE FOR INDIRECT, SPECIAL, OR CONSEQUENTIAL
            DAMAGES (OR ANY LOSS OF REVENUE, PROFITS OR DATA) ARISING IN CONNECTION WITH THIS AGREEMENT EVEN IF THE
            GROUP OR ANY MEMBER THEREOF (INCLUDING WinningPartner) HAS BEEN ADVISED BY THE AFFILIATE OF THE POSSIBILITY
            OF SUCH DAMAGES. FURTHER, WinningPartners AGGREGATE LIABILITY TO THE AFFILIATE ARISING WITH RESPECT TO THIS
            AGREEMENT WILL NOT EXCEED THE TOTAL REFERRAL FEES PAID OR PAYABLE TO THE AFFILIATE UNDER THIS AGREEMENT IN
            THE PRECEEDING 12 MONTH PERIOD. Nothing in this Agreement shall be construed to provide any rights, remedies
            or benefits to any person or entity not a party to this Agreement. Our obligations under this Agreement do
            not constitute personal obligations of shareholders. Any liability arising under this Agreement shall be
            satisfied solely from the Referral Fee generated and is limited to direct damages.With specific regard to
            the relationship between the parties governed by this Agreement, nothing contained in this clause 9.1.1
            shall be construed as a limitation of liability in the event of Groups causing personal injury or death or
            its act(s) of fraud, dishonesty or wilful misconduct</p>
          <p><b>8.1.2.</b> The Group may in its sole discretion, with or without notice, use any available means to
            block or restrict certain players, sign ups, deposits or play patterns so as to reduce the number of
            manipulative, fraudulent or unprofitable transactions or for any reason whatsoever, including but not
            limited to daily or monthly purchase limits, address verification or negative and positive credit card
            databases. The Group does not guarantee or warrant the success of its fraud and manipulation prevention
            efforts.</p>
          <p><b>8.2</b> Governing Law This Agreement shall be governed by the laws of England and Wales and the parties
            hereto submit to the exclusive jurisdiction of the English courts in respect of any dispute that may arise
            out of or be related to this Agreement.</p>
          <p><b>8.3</b> Assignability You may not assign this Agreement, by operation of law or otherwise, without our
            prior written consent. Subject to that restriction, this Agreement will be binding and enforceable against
            you and WinningPartner.</p>
          <p><b>8.4</b> Non-Waiver Our failure to enforce your strict performance of any provision of this Agreement
            will not constitute a waiver of our right to subsequently enforce such provision or any other provision of
            this Agreement. NO ORAL MODIFICATIONS, ADDITIONS OR DELETIONS OF THIS AGREEMENT BY YOU ARE PERMITTED OR WILL
            BE RECOGNIZED BY WinningPartner. None of our employees, officers, consultants or agents may verbally alter,
            modify or waive any provision of this Agreement.</p>
          <p><b>8.5</b> Remedies Our rights and remedies shall not be mutually exclusive, i.e. the exercise of one or
            more of the provisions of this Agreement shall not preclude the exercise of any other provision. You
            acknowledge, confirm and agree that damages may be inadequate for a breach or a threatened breach of this
            Agreement and, in the event of a breach, or threatened breach of any provision of this Agreement, the
            respective rights and responsibilities of the parties may be enforceable by specific performance, injunction
            or other equitable remedy. Nothing contained in this Agreement shall limit or affect any of our rights at
            law, or otherwise, for a breach or threatened breach of any provision of this Agreement, it being the intent
            of this provision to make clear that our respective rights and obligations shall be enforceable in equity as
            well as at law or otherwise.</p>
          <p><b>8.6</b> Severability/Waiver Whenever possible, each provision of this Agreement shall be interpreted in
            such a manner as to be effective and valid under applicable law but, if any provision of this Agreement is
            held to be invalid, illegal or unenforceable in any respect, such provision will be ineffective only to the
            extent of such invalidity or unenforceability, without invalidating the remainder of this Agreement or any
            other provision. No waiver will be implied from conduct or failure to enforce any rights and must be in
            writing to be effective.</p>
          <p><b>8.7</b> Disclosure The affiliate acknowledges that it understands that gambling laws may vary from city
            to city, state to state and country to country. YOU HAVE INDEPENDENTLY EVALUATED THE LAWS IN YOUR LOCALE
            WHICH APPLY TO YOUR ACTIVITIES AND REPRESENT TO WinningPartner AND THE GROUP THAT YOU MAY PARTICIPATE IN THE
            GROUP AFFILIATE PROGRAM WITHOUT VIOLATING ANY APPLICABLE RULES OR LAWS. By submitting your request to become
            an affiliate, you acknowledge that you have read this Agreement and have independently evaluated the
            desirability of participating in this program and are not relying on any representation, guarantee, or
            statement other that what is set forth in this Agreement, and that you agree to all its terms and
            conditions. YOU ACKNOWLEDGE THAT YOU HAVE READ AND AGREE TO ALL OF THE TERMS OF THIS AGREEMENT, INCLUDING,
            BUT NOT LIMITED TO, THE LIABILITY LIMITATIONS AND DISCLAIMERS IN CAPITALIZED LETTERS ABOVE.</p>
          <h6>8.8 Payments</h6>
          <p><b>8.8.1</b> The affiliate acknowledges that payments made by WinningPartner will only occur when the
            threshold of 500 GBP is met and confirmed by the account manager.</p>
          <p><b>8.8.2</b> The affiliate acknowledges that payments will occur after Net (60) days after calendar end.
          </p>
          <p><b>8.8.3</b> Unless negotiated otherwise, negative revenue will be carried over to the following month
            until profits surpass the revenue loss.</p>
          <p><b>8.8.4</b> An FTD will not be considered valid for CPA commission for the following reasons; the user is
            already an existing player at another brand within the Winning Partner portfolio.</p>
          <p><b>8.8.5</b> Unless agreed otherwise, CPA commission will only be payable when the FTD count has entirely
            fulfilled the agreed upon player test.</p>
          <p><b>8.9</b> No Third Parties A person who is not a party to this Agreement has no right under the Contracts
            (Rights of Third Parties) Act 1999 to enforce or to enjoy the benefit of any term of this Agreement;
            provided, however, that such exclusion as provided by this clause shall in no case apply to any member of
            the Group where any or all of the affiliates breaches of this Agreement cause or can potentially cause
            damage, regulatory fine or other sanction, or the like, to such Group company(ies).</p>
          <p><b>8.9.1.</b> In the following circumstances, an FTD will not be considered valid for CPA commission but
            will revert to the default revshare commission of 35%:</p>
          <ul>
            <li>the FTD occurs after the initial player cap has been reached and a new player cap has not been agreed.
            </li>
            <li>the user is already an existing player at another brand within the Winning Partner portfolio.</li>
            <li>the user is not located from a pre-approved GEO.</li>
            <li>the user self-excludes within 7 days of their first deposit.</li>
          </ul>
          <p><b>8.9.2.</b> In the following circumstances, an FTD will not be considered valid for any commission:</p>
          <ul>
            <li>the user is flagged as a ‘Bonus abuser’ within the Winning Partner system</li>
            <li>the user fails ‘KYC’ checks</li>
          </ul>
          <p><b>8.10</b> In case you earn a revenue share commission generated from the promotion of multiple brands
            within the affiliate program, the commission will be calculated individually for each brand and then
            combined together to determine the total commission earned.</p>
          <p><b>8.11</b> Effect of This Agreement This Agreement constitutes the entire agreement and understanding
            between the affiliate and WinningPartner. This Agreement supersedes all previous agreements, representations
            or arrangements whether made orally or in writing.</p>
          <p><b>8.12</b> Notices Any notifications related to this Agreement shall be made by email from one party
            hereto to the other at the email address set forth in the signature section below. Such email shall be
            deemed as delivered when (i) acknowledged as having been received by the other party; or (ii) when there is
            no bounceback or other notification of non delivery received by the sending party for a period of 24 hours
            from despatch of such notification email.</p>
          <p>In accordance with section 330 of the Gambling Act of 2005, it is unlawful to advertise gambling on
            websites which do not carry the correct permits/license to offer such gambling. In order to uphold the
            objectives of the Gambling Act of 2005, we have a zero-tolerance stance to working with any affiliate who
            undertakes such activity and if we discover such this agreement will be terminated and no monies owed will
            be paid and we will consider to report you to the relevant authorities. For the avoidance of doubt, this
            means that our Brand cannot be on a website which promotes non-UK licensed brands to UK players or UK
            Licensed Brands that clearly advertise a service/product in breach of the <a
              href="https://www.gamblingcommission.gov.uk/licensees-and-businesses/lccp">Licence Conditions and Codes of
              Practice of the UKGC (LCCP)</a></p>
          <p><u>Promote means that our brand:</u></p>
          <ul>
            <p>
              <li>Is on a website/affiliate site which has a. co.uk domain or the domain name includes a UK reference
                (e.g., ukcasinolist.com) which has brands which are not licensed by the UK Gambling Commission
              </li>
              <li>Our brand is on a website/affiliate site with brands not licensed by the UK Gambling commission yet
                are trying to attract UK players by having offers in GBP, or offers that can be deemed as attracting UK
                customers, e.g., using UK flag, refer to the UK National self-exclusion register GAMSTOP, promotions
                with content relevant to the country UK, or suggest in any way that the Brand with no UK Gambling
                Commission license is available for UK players and/or has a UK License
              </li>
              <li>The site contains sections specifically referring to UK player (i.e. best UK/non-UK casinos)</li>
            </p>
          </ul>
          <p><u>Service/product in breach of the LCCP means:</u></p>
          <ul>
            <p>
              <li>References about casinos not offering the UK National self-exclusion register GAMSTOP or tips on how
                to cancel or avoid a GAMSTOP registration
              </li>
              <li>References about casinos not requiring registration/verification/account or no KYC
                information/documents (anonymous casinos)
              </li>
              <li>References about casinos allowing reversing withdrawals</li>
              <li>References about tips on how to cheat or defraud casino systems or games</li>
              <li>Any other reference not inline and/or in breach of the LCCPs</li>
            </p>
          </ul>
          <p>All of the above example should be seen as non-exhaustive. If you are ever in doubt please contact your
            account manager for compliance approval before moving forward with contracting with such an affiliate.
            Furthermore, in cases of affiliate websites advertising both UK and non-UK licensed brands we strongly
            advise to ensure that non-UK licensed brands bear a warning/note that the said brand/casino is not available
            to UK players.</p>
        </div>
      </section>
      <ScrollButton />
    </>
  );
};

export default Terms;
