import React from 'react';
import Pdf from "../../../assets/wp-fact-sheet.pdf"
import '../Pdf.scss';

const PDF_Fact = () => {

    return (
        <div className="pdf">
            <a href={Pdf} className='pdf__link'><span>Download the Winning Partner fact sheet️</span><span><i className="fas fa-angle-double-right"></i></span></a>
        </div>
    );
}

export default PDF_Fact
