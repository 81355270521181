import React from 'react'
import BrandsBlocks from '../../../components/Brands/BrandsBlocks/BrandsBlocks'
import BrandsCommission from '../../../components/Brands/BrandsCommission/BrandsCommission'
import BrandsHero from '../../../components/Brands/BrandsHero/BrandsHero'
import BrandsPayment from '../../../components/Brands/BrandsPayment/BrandsPayment'
import BrandsProvider from '../../../components/Brands/BrandsProvider/BrandsProvider'
import BrandsReviews from '../../../components/Brands/BrandsReviews/BrandsReviews'
import BrandsScreenshots from '../../../components/Brands/BrandsScreenshots/BrandsScreenshots'
import BrandsService from '../../../components/Brands/BrandsService/BrandsService'
import BrandsStandard from '../../../components/Brands/BrandsStandard/BrandsStandard'
import MiamiJackpotData from './MiamiJackpotData';
import HelmetMeta from '../../../components/HelmetMeta/HelmetMeta'

const MiamiJackpot = () => {
  return (
    <>
        <HelmetMeta title={`Miami Jackpots Affiliate Programme - Sign up now`} />
        <main className="lpcasino">
            <BrandsHero data={MiamiJackpotData.hero} />
            <BrandsService data={MiamiJackpotData.service} />
            <BrandsScreenshots data={MiamiJackpotData.screenshots} />
            <BrandsCommission data={MiamiJackpotData.commission} />
            <BrandsProvider data={MiamiJackpotData.providers} />
            <BrandsStandard data={MiamiJackpotData.standard} />
            <BrandsPayment data={MiamiJackpotData.payments} />
            <BrandsBlocks data={MiamiJackpotData.blocks} />
            <BrandsReviews data={MiamiJackpotData.reviews} />
        </main>
    </>
  )
}

export default MiamiJackpot