import React from 'react';
import Pdf from "../../assets/who-are-we.pdf"
import './Pdf.scss';

const PDF = () => {

    return (
        <div className="pdf">
            <a href={Pdf} className='pdf__link'><span>See more</span> <span><i className="fas fa-angle-double-right"></i></span></a>
        </div>
    );
}

export default PDF
