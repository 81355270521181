import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { ReactComponent as CalendarIcon } from "../../assets/calendar.svg";
import { ReactComponent as ChartIcon } from "../../assets/line-chart.svg";
import { ReactComponent as LinkIcon } from "../../assets/link.svg";
import { ReactComponent as StatsIcon } from "../../assets/stats.svg";
import { ReactComponent as UsersIcon } from "../../assets/users.svg";
import { ReactComponent as CasinoIcon } from "../../assets/casino-chip-nav.svg";
import { ReactComponent as FlashIcon } from "../../assets/flash.svg";
import { ReactComponent as PictureIcon } from "../../assets/picture.svg";
import { ReactComponent as AffiliateIcon } from "../../assets/affiliate.svg";
import { ReactComponent as MessagesIcon } from "../../assets/envelope-solid.svg";
import { ReactComponent as ProfileIcon } from "../../assets/settings.svg";
import fetchData from "../../api/data";
import { CalendarMonth } from "@mui/icons-material";

const Navbar = () => {
  const [unreadMessages, setUnreadMessages] = useState(false);

  const handleClick = (e) => {
    const toggleLink = document.querySelectorAll(".toggle-menu");
    toggleLink.forEach((item) => {
      if (item.classList.contains("active-toggle")) {
        item.classList.remove("active-toggle");
      }
    });
    e.target.parentNode.classList.toggle("active-toggle");
  };

  useEffect(() => {
    return fetchData({
      url: "/api/v3/affiliate-messages/all/user/unread",
      onSuccess: (response) => setUnreadMessages(response.data),
    });
  }, []);

  return (
    <div className="menu-container">
      <nav className="main-navbar">
        <ul className="main-menu">
          {/* <span className="menu-tab__title">Performance</span> */}
          <li className="toggle-menu" onClick={handleClick}>
            <span className="menu-item__title">Dashboard</span>
            <NavLink
              activeClassName="is-active"
              className="nav-link"
              to="/admin/overview"
            >
              <CalendarMonth /> Overview
            </NavLink>
          </li>
          <li className="toggle-menu" onClick={handleClick}>
            <span className="menu-item__title">Reports</span>
            <NavLink
              activeClassName="is-active"
              className="nav-link"
              to="/admin/daily"
            >
              <CalendarIcon /> Daily
            </NavLink>
            <NavLink
              activeClassName="is-active"
              className="nav-link"
              to="/admin/dynamic_variable"
            >
              <StatsIcon /> Variables
            </NavLink>
            <NavLink
              exact={true}
              activeClassName="is-active"
              className="nav-link"
              to="/admin/brands"
            >
              <ChartIcon /> Brands
            </NavLink>
            <NavLink
              activeClassName="is-active"
              className="nav-link"
              to="/admin/players_report"
            >
              <UsersIcon /> Players
            </NavLink>
            <NavLink
              activeClassName="is-active"
              className="nav-link"
              to="/admin/casinos"
            >
              <CasinoIcon /> Casinos
            </NavLink>
          </li>
          <span className="menu-item__title">Subaffilation</span>
          <li>
            <NavLink
              activeClassName="is-active"
              className="nav-link"
              to="/admin/subaffilation"
            >
              <AffiliateIcon /> Subaffilation
            </NavLink>
          </li>
          <span className="menu-item__title">Messages </span>
          <li>
            {/*Unread*/}
            {
              unreadMessages ?
                <NavLink
                  activeClassName="is-active"
                  className="nav-link"
                  to="/admin/messages"
                  style={{ backgroundColor: "red" }}
                >
                  <MessagesIcon /> Messages (*)
                </NavLink>
                :
                <NavLink
                  activeClassName="is-active"
                  className="nav-link"
                  to="/admin/messages"
                >
                  <MessagesIcon /> Messages
                </NavLink>
            }
          </li>
          <span className="menu-item__title">Manage</span>
          <li>
            <NavLink
              activeClassName="is-active"
              className="nav-link"
              to="/admin/campaigns"
            >
              <LinkIcon /> Links
            </NavLink>
            <NavLink
              activeClassName="is-active"
              className="nav-link"
              to="/admin/assets"
            >
              <PictureIcon /> Assets
            </NavLink>
            <NavLink
              activeClassName="is-active"
              className="nav-link"
              to="/admin/reports"
            >
              <FlashIcon /> API Reports
            </NavLink>
          </li>
          <span className="menu-item__title">Settings</span>
          <li>
            <NavLink
              activeClassName="is-active"
              className="nav-link"
              to="/admin/profile/user-settings"
            >
              <ProfileIcon /> Profile
            </NavLink>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Navbar;
