import { useEffect, useMemo, useState } from "react";
import { AppBar, MenuItem, Select, Typography } from "@mui/material";
import DataTable from "react-data-table-component";
import { Circular } from "../../components/Circular/Circular";
import connectTopLine from "../../components/HOC/connectTopLine";
import fetchData from "../../api/data";
import { makeStyles } from "@mui/styles";
import { toastApiError } from "../../utils/toastApiError";

const months = [
  { text: "January", value: "01" },
  { text: "February", value: "02" },
  { text: "March", value: "03" },
  { text: "April", value: "04" },
  { text: "May", value: "05" },
  { text: "June", value: "06" },
  { text: "July", value: "07" },
  { text: "August", value: "08" },
  { text: "September", value: "09" },
  { text: "October", value: "10" },
  { text: "November", value: "11" },
  { text: "December", value: "12" },
];

const years = {
  2020: "2020",
  2021: "2021",
  2022: "2022",
  2023: "2023",
  2024: "2024",
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme?.palette?.background?.paper, // FIXME why is palette not defined?
    margin: "0 -15px",
  },
  appBar: {
    backgroundColor: "#5361f5",
  },
  refCode: {
    padding: "15px",
    marginBottom: "0px",
    textAlign: "center",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  total: {
    padding: "15px",
    marginBottom: "0px",
  },
  select: {
    marginRight: "15px",
    minWidth: "100px",
  },
}));

const columns = [
  {
    name: "SubAffiliate",
    selector: (row) => row.subAffiliateEmail,
    sortable: true,
  },
  {
    name: "Clicks",
    selector: (row) => row.clicks,
    sortable: true,
  },
  {
    name: "Registrations",
    selector: (row) => row.registrations,
    sortable: true,
  },
  {
    name: "FTDs",
    selector: (row) => row.ftds,
    sortable: true,
  },
  {
    name: "Deposits",
    selector: "deposits",
    sortable: true,
  },
  {
    name: "Net Cash",
    selector: "netCash",
    sortable: true,
  },
  {
    name: "Total Commission",
    selector: "totalCommission",
    sortable: true,
  },
  {
    name: "Commission Cut",
    selector: "commissionCut",
    sortable: true,
  },
];


const Subaffilation = () => {
  const [loading, setLoading] = useState(false);
  const [mySubs, setMySubs] = useState([]);
  const [link, setLink] = useState({});
  const [month, setMonth] = useState(new Date().getMonth() + 1);
  const [year, setYear] = useState(new Date().getFullYear());
  const classes = useStyles();

  useEffect(() => {
    setLoading(true);
    return fetchData({
      url: "/api/v3/reports/subaffiliation/index",
      onSuccess: (response) => {
        setLink(response.data);
      },
      onError: toastApiError,
      onCallback: () => setLoading(false),
    });
  }, []);

  useEffect(() => {
    setLoading(true);
    return fetchData({
      url: `/api/v3/reports/subaffiliation/all?year=${year}&month=${month}`,
      onSuccess: (response) => {
        setMySubs(response.data.reports);
      },
      onError: toastApiError,
      onCallback: () => setLoading(false),
    });
  }, [month, year]);

  const getTotalCommission = useMemo(() => {
    const totalOfCommissionCut = mySubs.reduce((acc, sub) => acc + parseInt(sub.commissionCut) / 100, 0);
    // FIXME that's wrong, subAffiliationStaticCut shouldn't be applied even if there are no sub affiliates
    //       but at the moment it's always 0
    return parseInt(link.subAffiliationStaticCut) + totalOfCommissionCut;
  }, [link, mySubs]);

  const dataSubs = useMemo(
    () =>
      mySubs.map((sub) => {
        return {
          ...sub,
          deposits: `£${sub.deposits % 2 === 0 ? sub.deposits.toFixed(0) : sub.deposits.toFixed(2)}`,
          commissionCut: `£${(sub.commissionCut / 100).toFixed(2)}`,
          totalCommission: `£${(sub.cpaCommission + sub.revShareCommission).toFixed(2)}`,
          netCash: `£${sub.netCash.toFixed(2)}`,
        };
      }),
    [mySubs]);

  return (
    <div className={classes.root}>
      <div className={classes.row}>
        <Typography variant="h5" gutterBottom className={classes.refCode}>
          Your referral code: {link.referralCode}
        </Typography>
        <div className={classes.filters}>
          <Select
            labelId="demo-simple-select-label"
            className={classes.select}
            value={month}
            onChange={(e) => setMonth(e.target.value)}
          >
            {months.map((month, index) => (
              <MenuItem key={month.value} value={index + 1}>
                {month.text}
              </MenuItem>
            ))}
          </Select>
          <Select
            labelId="demo-simple-select-label"
            className={classes.select}
            value={year}
            onChange={(e) => setYear(e.target.value)}
          >
            {Object.entries(years).map(([id, year]) => (
              <MenuItem key={id} value={id}>
                {year}
              </MenuItem>
            ))}
          </Select>
        </div>
      </div>
      <div className={classes.row}>
        <Typography variant="h5" gutterBottom className={classes.refCode}>
          Your Static Cut: £{link.subAffiliationStaticCut}
        </Typography>
        <Typography variant="h5" gutterBottom className={classes.refCode}>
          Your Commission: {link.subAffiliationPercentage} %
        </Typography>
      </div>
      <AppBar position="static" className={classes.appBar}>
        <DataTable
          title="My Subaffiliations"
          pagination
          data={dataSubs}
          columns={columns}
          progressPending={loading}
          progressComponent={<Circular />}
          paginationComponentOptions={{ noRowsPerPage: true }}
        />
      </AppBar>
      <AppBar position="static" className={classes.appBar}>
        <Typography variant="h6" gutterBottom className={classes.total}>
          Your total commission for <b>{months[month - 1].text}</b> <b>{year}</b> is{" "}
          <b>£{getTotalCommission}</b>
        </Typography>
      </AppBar>
    </div>
  );
};

export default connectTopLine(false, false, Subaffilation);
