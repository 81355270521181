import React from 'react';
import "./BrandsHero.scss";

const BrandsHero = ({data}) => {
  return (
    <section className="lpcasino__hero_section">
        <div className="section-inner">
            <div className="lpcasino__container">    
                <div className="lpcasino__left_section">
                    <h2 className="lpcasino__hero_title">{data.title}</h2>
                    <p className='lpcasino__hero_paragraph'>{data.paragraph}</p>

                    <div className="lpcasino__hero_btn_section">
                        {
                            data.button.map((item) => {
                                return (
                                    <button key={item.id}><a href={item.link}>{item.title}</a></button>
                                )
                            })
                        }
                    </div>
                </div>

                <div className="lpcasino__right_section">
                    <img src={data.img} alt="" />
                </div>
            </div>
        </div>    
    </section>
  )
}

export default BrandsHero