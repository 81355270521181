import React from 'react'
import BrandsBlocks from '../../../components/Brands/BrandsBlocks/BrandsBlocks'
import BrandsCommission from '../../../components/Brands/BrandsCommission/BrandsCommission'
import BrandsHero from '../../../components/Brands/BrandsHero/BrandsHero'
import BrandsPayment from '../../../components/Brands/BrandsPayment/BrandsPayment'
import BrandsProvider from '../../../components/Brands/BrandsProvider/BrandsProvider'
import BrandsReviews from '../../../components/Brands/BrandsReviews/BrandsReviews'
import BrandsScreenshots from '../../../components/Brands/BrandsScreenshots/BrandsScreenshots'
import BrandsService from '../../../components/Brands/BrandsService/BrandsService'
import BrandsStandard from '../../../components/Brands/BrandsStandard/BrandsStandard';
import RichRideData from './RichRideData';
import HelmetMeta from '../../../components/HelmetMeta/HelmetMeta'

const RichRide = () => {
  return (
    <>
      <HelmetMeta title={`RichRide Casino Affiliate Programme - Sign up now`} />
      <main className="lpcasino">
          <BrandsHero data={RichRideData.hero} />
          <BrandsService data={RichRideData.service} />
          <BrandsScreenshots data={RichRideData.screenshots} />
          <BrandsCommission data={RichRideData.commission} />
          <BrandsProvider data={RichRideData.providers} />
          <BrandsStandard data={RichRideData.standard} />
          <BrandsPayment data={RichRideData.payments} />
          <BrandsBlocks data={RichRideData.blocks} />
          <BrandsReviews data={RichRideData.reviews} />
      </main>
    </>
  )
}

export default RichRide
