import { useCallback, useEffect, useRef, useState } from "react";
import { Redirect } from "react-router-dom";
import { Alert, Button, Container, Grid, TextField } from "@mui/material";
import { CSSTransition } from "react-transition-group";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
import "./resetPassword.scss";

const validatePassword = (password) => {
  /* if(!first.toLowerCase() === second.toLowerCase()){
    return false;
  } */
  return password.length >= 8;

};


export const ResetPassword = () => {
  const [user, setUser] = useState({
    password: "",
  });
  const [error, setError] = useState({
    status: false,
    message: "",
  });
  const [success, setSuccess] = useState(false);

  const [captcha, setCaptcha] = useState("");
  const [erCaptcha, setErCaptcha] = useState(false);
  const reCaptchaRef = useRef();

  const handleChange = useCallback(
    (e) => {
      setUser({ ...user, [e.target.name]: e.target.value });
    },
    [setUser, user],
  );

  const source = axios.CancelToken.source();
  const ref_code = new URL(window.location.href).searchParams.get('reset_password_token');

  const handleSubmit = (password) => {
    setError({
      status: false,
      message: "",
    });
    if (!validatePassword(password)) {
      setError({
        status: true,
        message: "Password must be 8 characters or more",
      });
      return;
    }
    axios
      .post(`/api/v3/user/update-password`, {
        password: password,
        resetPasswordToken: ref_code,
      })
      .then((response) => {
        if (response.status === 200) {
          setSuccess(true);
        }
      })
      .catch(() => {
        setError({
          status: true,
          message: "Could not validate Reset Password Token! Please contact an administrator.",
        });
      });
  };

  useEffect(() => {
    return () => {
      source.cancel();
    };
  }, [source]);

  const handleCaptchaChange = useCallback(
    (e) => {
      setCaptcha(e);
    },
    [setCaptcha],
  );
  if (ref_code !== undefined) {
    return (
      <Container className={"center-block"} maxWidth="sm">
        <h1 className="sign-in-title">Reset Password</h1>
        {success ? (
          <Redirect to="/login" />
        ) : (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit(user.password);
              e.target.reset();
            }}
          >
            <CSSTransition
              in={error.status}
              timeout={500}
              classNames="my-node"
              unmountOnExit
            >
              <Alert severity="error">{error.message}</Alert>
            </CSSTransition>
            <Grid item xs={12}>
              <TextField
                required
                id="standard-required"
                label="New password"
                type="password"
                variant="outlined"
                name="password"
                defaultValue={user.password}
                onChange={handleChange}
              />
            </Grid>
            <div
              className="form-group"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <ReCAPTCHA
                ref={reCaptchaRef}
                sitekey="6Lc6O9AUAAAAAF6myYURrvZwW43E4ShPeKOVKCHe"
                onChange={handleCaptchaChange}
              />
              {erCaptcha && <Alert severity="error">ReCaptcha is required</Alert>}
            </div>
            <Grid
              item
              container
              xs={12}
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Button
                size="large"
                variant="contained"
                type="submit"
                color="primary"
              >
                Update password
              </Button>
            </Grid>
          </form>
        )}
      </Container>
    );
  } else {
    return (
      <Container>
        <h3 style={{ textAlign: "center" }}>Invalid token, please make sure you have used this page correctly.</h3>
      </Container>
    );
  }
};
