import "./faqs.scss";
import { PDF } from "../../components/PDF";
import { PDF_Expect } from "../../components/PDF/PDF_Expect";
import { PDF_Fact } from "../../components/PDF/PDF_Fact";
import ScrollButton from "../../components/ScrollButton/ScrollButton";

const FAQs = () => {


  return (
    <>
      <section id="terms-top">
        <div className="innerWrap">
          <h2>WinningPartner FAQs</h2>
        </div>
      </section>

      <section id="main" className="content">
        <div className="innerWrap terms-wrap">
          <div className="faq">
            <div className="faq-title faq__title">
              Who are we?
            </div>
            <div className="card-faq faq__card">
              <li> Winning Partner is an affiliate network with over 30 years of collective experience that seeks
                partners to provide traffic for our casino and bingo sites. We're looking to establish long term,
                mutually beneficial relationships with affiliates.
              </li>
            </div>
            <PDF> </PDF>
          </div>
          <hr />
          <div className="faq">
            <div className="faq-title faq__title">
              How can I earn money?
            </div>
            <div className="card-faq faq__card">
              <li> We pay affiliates for every player they send, which means the better your traffic, the more
                commission you earn. Every time you send a player who deposits money on one of our sites you can earn
                commission. The type of commission you earn depends on the deal we negotiate.
              </li>
            </div>
          </div>
          <hr />
          <div className="faq">
            <div className="faq-title faq__title">
              Why should I choose Winning Partner?
            </div>
            <div className="card-faq faq__card">
              <li> There are several benefits to being a member of the Winning Partner affiliate program. Including our
                large portfolio of available casino and bingo brands, and our generous commission structure, we also
                have a dedicated team of affiliate managers offering guidance and support. We provide affiliates with a
                variety of data reports so they can assess their own performance.
              </li>
            </div>
            <PDF_Expect> </PDF_Expect>
          </div>
          <hr />
          <div className="faq">
            <div className="faq-title faq__title">
              How does the sign-up process work?
            </div>
            <div className="card-faq faq__card">
              <li> You can sign-up to become a member of our affiliate programme <a
                href="https://winningpartner.com/signup">here</a>. All you have to do is provide us with the information
                required. From there our team will assess your application and be in touch!
              </li>
            </div>
          </div>
          <hr />
          <div className="faq">
            <div className="faq-title faq__title">
              How can I track my earnings?
            </div>
            <div className="card-faq faq__card">
              <li> We provide a number of reports which help affiliates track commission daily, monthly and per player.
                Our user interface provides affiliates with transparent reports so they can see their true performance.
              </li>
            </div>
          </div>
          <hr />
          <div className="faq">
            <div className="faq-title faq__title">
              Are there any sign-up costs or hidden fees?
            </div>
            <div className="card-faq faq__card">
              <li> Put simply, no. There are no costs in joining our platform. All you have to do is provide us with
                casino players.
              </li>
            </div>
          </div>
          <hr />
          <div className="faq">
            <div className="faq-title faq__title">
              Where can I find out more information?
            </div>
            <div className="card-faq faq__card faq__card">
              <li>Our terms and conditions can be found <a href="https://winningpartner.com/terms">here</a>.</li>
              <li>Our privacy policy can be found <a href="https://www.winningpartner.com/privacy-policy">here</a>.</li>
              <li>The list of brands you can promote can be found <a href="https://winningpartner.com/#brands">here</a>.
              </li>
              <li>If you have any further questions, please get in <a
                href="https://www.winningpartner.com/#contact   ">here</a>.
              </li>
            </div>
          </div>
          <hr />
          <PDF_Fact> </PDF_Fact>
          <br />
        </div>
      </section>
      <ScrollButton />
    </>
  );
};

export default FAQs;
