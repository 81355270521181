import ReactDOM from "react-dom";
import "./index.css";
import { App } from "./App";
import { BrowserRouter } from "react-router-dom";
import { ReportContextProvider } from "./components/HOC/ReportContext";

ReactDOM.render(
  (
    <ReportContextProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ReportContextProvider>
  ),
  document.getElementById("root"),
);
