import { Table } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

export default function Variable({ variables, loading }) {
  if (loading) {
    return (
      <Table striped bordered hover>
        <thead>
        <tr>
          <th>Dynamic variables report</th>
        </tr>
        </thead>
        <tbody></tbody>
      </Table>
    );
  }

  return (
    <Table striped bordered hover className="report-table">
      <thead>
      <tr>
        <th>Dynamic variables report</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td>https://winningpartner.com/api/v3/api_reports/variables?date_from=2021-01-01&date_to=2021-01-31</td>
      </tr>
      </tbody>
    </Table>
  );
}
