import React from 'react';
import BrandsHero from '../../../components/Brands/BrandsHero/BrandsHero';
import BrandsService from '../../../components/Brands/BrandsService/BrandsService';
import BrandsScreenshots from '../../../components/Brands/BrandsScreenshots/BrandsScreenshots';
import BrandsCommission from '../../../components/Brands/BrandsCommission/BrandsCommission';
import BrandsProvider from '../../../components/Brands/BrandsProvider/BrandsProvider';
import BrandsStandard from '../../../components/Brands/BrandsStandard/BrandsStandard';
import BrandsPayment from '../../../components/Brands/BrandsPayment/BrandsPayment';
import BrandsBlocks from '../../../components/Brands/BrandsBlocks/BrandsBlocks';
import BrandsReviews from '../../../components/Brands/BrandsReviews/BrandsReviews';
import ThreeCasinoData from './ThreeCasinoData';
import HelmetMeta from '../../../components/HelmetMeta/HelmetMeta';

const ThreeCasino = () => {
  return (
    <>
        <HelmetMeta title={`333 Casino Affiliate Programme - Sign up now`} />
        <main className="lpcasino">
            <BrandsHero data={ThreeCasinoData.hero} />
            <BrandsService data={ThreeCasinoData.service} />
            <BrandsScreenshots data={ThreeCasinoData.screenshots} />
            <BrandsCommission data={ThreeCasinoData.commission} />
            <BrandsProvider data={ThreeCasinoData.providers} />
            <BrandsStandard data={ThreeCasinoData.standard} />
            <BrandsPayment data={ThreeCasinoData.payments} />
            <BrandsBlocks data={ThreeCasinoData.blocks} />
            <BrandsReviews data={ThreeCasinoData.reviews} />
        </main>
    </>
  )
}

export default ThreeCasino