import { useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { Circular } from "../../components/Circular/Circular";
import connectTopLine from "../../components/HOC/connectTopLine";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import fetchData from "../../api/data";
import { toastApiError } from "../../utils/toastApiError";

const Messages = () => {
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [readCounter, setReadCounter] = useState(0);

  const handleReadButtonClicked = (id) => {
    try {
      const source = axios.CancelToken.source();
      const token = window?.localStorage?.getItem("accessToken");
      axios.put(
        `/api/v3/affiliate-messages/${id}/mark-as-read`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          cancelToken: source.token,
        },
      ).then(() => setReadCounter(readCounter + 1));
    } catch (err) {
      toastApiError(err);
    }
  };

  const columns = [
    {
      name: "Timestamp",
      selector: (row) => row.createdAt,
      sortable: true,
      cell: e => new Date(e.createdAt).toLocaleDateString(),
    },
    {
      name: "Subject",
      selector: (row) => row.subject,
      sortable: true,
    },
    {
      name: "Message",
      selector: (row) => row.message,
      sortable: true,
    },
    {
      name: "Mark as Read",
      selector: (row) => row.message,
      sortable: false,
      cell: e => {
        if (e.unread) {
          return <button className="btn btn-info" onClick={(el) => handleReadButtonClicked(e.id)}>Mark As Read</button>;
        } else {
          return <button disabled className="btn btn-success">Read</button>;
        }
      },
    },
  ];

  useEffect(() => {
    setLoading(true);
    return fetchData({
      url: "/api/v3/affiliate-messages/all/user",
      onSuccess: (response) => {
        setMessages(response.data);
      },
      onError: toastApiError,
      onCallback: () => setLoading(false),
    });
  }, [readCounter]);

  return (
    <Row>
      <div className="data__wrapper">
        <DataTable
          title="Your Messages"
          columns={columns}
          data={messages}
          progressPending={loading}
          progressComponent={<Circular />}
        />
      </div>
    </Row>
  );
};

export default connectTopLine(false, false, Messages);
