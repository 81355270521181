import miamijackpots from "../../assets/brands/miamijackpots.png";
import bbcasino from "../../assets/brands/bbcasino.png";
import slotsjungle from "../../assets/brands/slotsjungle.png";
import jackpotslot from "../../assets/brands/jackpotslot.png";
import spinslots from "../../assets/brands/spinslots.png";
import slotster from "../../assets/brands/slotster.png";
import vipbet from "../../assets/brands/vipbet.png";
import royalbets from "../../assets/brands/royalbets.png";
import casinoroo from "../../assets/brands/casinoroo.png";
import casino333 from "../../assets/brands/333casino.png";
import richride from "../../assets/brands/richride.png";
import diceden from "../../assets/brands/diceden.png";
import blastoffbingo from "../../assets/brands/blastoffbingo.png";
import heatbingo from "../../assets/brands/HeatBingo.png";
import winitbingo from "../../assets/brands/WinItBingo.png";
import qink from "../../assets/brands/qink.png";

const brands = [
  {
    id: 0,
    url: `/brands/miamijackpots`,
    className: "",
    path_img: miamijackpots,
  },
  {
    id: 1,
    url: `/brands/BBCasino`,
    className: "",
    path_img: bbcasino,
  },
  {
    id: 2,
    url: "https://casino.slotsjungle.com",
    className: "",
    path_img: slotsjungle,
  },
  {
    id: 3,
    url: "https://casino.jackpotslot.com",
    className: "",
    path_img: jackpotslot,
  },
  {
    id: 4,
    url: "https://casino.spinslots.com",
    className: "",
    path_img: spinslots,
  },
  {
    id: 5,
    url: "https://casino.slotster.com",
    className: "",
    path_img: slotster,
  },
  {
    id: 6,
    url: "https://casino.vipbet.com",
    className: "",
    path_img: vipbet,
  },
  {
    id: 7,
    url: "https://casino.royalbets.com",
    className: "",
    path_img: royalbets,
  },
  {
    id: 8,
    url: "https://play.casinoroo.com",
    className: "",
    path_img: casinoroo,
  },
  {
    id: 9,
    url: `/brands/333casino`,
    className: "",
    path_img: casino333,
  },
  {
    id: 10,
    url: `/brands/richride`,
    className: "",
    path_img: richride,
  },
  {
    id: 11,
    url: "https://www.diceden.com",
    className: "",
    path_img: diceden,
  },
];

export default brands;
