import React from 'react';
import './BrandsScreenshots.scss';
import InfiniteCarousel from 'react-leaf-carousel';

const BrandsScreenshots = ({ data }) => {
  return (
    <section className='lpcasino__screenshots'>
        <div className="section-heading">
            <h3>{data.title.split(' ')[0]}</h3>
            <h3>{data.title.split(' ')[1]}</h3>
        </div>

        <div className="lpcasino__screenshots_slide_box">
            <InfiniteCarousel
                breakpoints={[
                    {
                      breakpoint: 700,
                      settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                      },
                    },
                  ]}
                dots={true}
                showSides={true}
                sidesOpacity={.5}
                sideSize={.4}
                slidesToScroll={1}
                slidesToShow={2}
                arrows={false}
                scrollOnDevice={true}
                autoCycle={true}
                cycleInterval={3500}
                pauseOnHover={true}
            >
                {
                    data.images.map((item,index) => {
                        return (
                            <div className="lpcasino__screenshots_slide_single" key={index}>
                                <img src={item} />
                            </div>
                        )
                    })
                }
            </InfiniteCarousel>
        </div>
    </section>
  )
}

export default BrandsScreenshots