import { Admin } from "./Admin";
import Navigation from "./components/Navigation/Navigation";
import { Col, Container, Row } from "react-bootstrap";
import { Route, Switch } from "react-router-dom";
import { PrivateRoute } from "./PrivateRoute";
import { Login } from "./pages/Login";
import { SignUp } from "./pages/SignUp";
import { ForgotPassword } from "./pages/ForgotPassword";
import { ResetPassword } from "./pages/ResetPassword";
import Home from "./pages/Home/Home";
import { Footer } from "./components/Footer";
import { Terms } from "./pages/Terms";
import { PrivacyPolicy } from "./pages/PrivacyPolicy";
import { FAQs } from "./pages/FAQs";
import Reviews from "./pages/Review/Reviews";
import IGBConference from "./pages/IGBConference/IGBConference";
import BBCasino from "./pages/Brands/BBCasino/BBCasino";
import { Profiles } from "./pages/Profiles";
import { Referral } from "./pages/Referral";
import ReactGA from "react-ga";
import { Toaster } from "react-hot-toast";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import ThreeCasino from "./pages/Brands/333Casino/333Casino";
import RichRide from "./pages/Brands/RichRide/RichRide";
import MiamiJackpot from "./pages/Brands/MiamiJackpot/MiamiJackpot";
import OinkBingo from "./pages/Brands/OinkBingo/OinkBingo";
import { AuthProvider } from "./auth/JWTContext";

export const App = () => {
  ReactGA.initialize("UA-61473152-2");
  ReactGA.pageview(window.location.pathname);

  return (
    <AuthProvider>
      <Toaster
        position="top-center"
        toastOptions={{
          style: {
            maxWidth: "100%"
          }
        }}
      />
      <div className="wrapper">
        <Container fluid className="main-container">
          <Row>
            <Col lg="12" className="top_menu">
              <Navigation />
            </Col>
            <Switch>
              <Route exact path="/" component={Home} />
              <Route path="/terms" component={Terms} />
              <Route path="/privacy-policy" component={PrivacyPolicy} />
              <Route path="/faqs" component={FAQs} />
              <Route path="/testimonials" component={Reviews} />
              <Route path="/igb" component={IGBConference} />
              <Route path="/brands/BBCasino" component={BBCasino} />
              <Route path="/brands/333Casino" component={ThreeCasino} />
              <Route path="/brands/richride" component={RichRide} />
              <Route path="/brands/miamijackpots" component={MiamiJackpot} />
              <Route path="/brands/OinkBingo" component={OinkBingo} />
              <Route path="/profiles" component={Profiles} />
              <Route path="/login" component={Login} />
              <Route path="/signup" component={SignUp} />
              <Route path="/forgot" component={ForgotPassword} />
              <Route path="/reset_password" component={ResetPassword} />
              <Route path="/r/:referral_code" component={Referral} />
              <PrivateRoute path="/admin" component={Admin} />
            </Switch>
            <Footer />
          </Row>
        </Container>
      </div>
    </AuthProvider>
  );
};
