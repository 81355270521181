import React from 'react'
import BrandsHero from '../../../components/Brands/BrandsHero/BrandsHero';
import BrandsService from '../../../components/Brands/BrandsService/BrandsService';
import OinkBingoData from './OinkBingoData';
import BrandsCommission from '../../../components/Brands/BrandsCommission/BrandsCommission';
import BrandsProvider from '../../../components/Brands/BrandsProvider/BrandsProvider';
import BrandsPayment from '../../../components/Brands/BrandsPayment/BrandsPayment';
import BrandsStandard from '../../../components/Brands/BrandsStandard/BrandsStandard';
import BrandsBlocks from '../../../components/Brands/BrandsBlocks/BrandsBlocks';
import BrandsScreenshots from '../../../components/Brands/BrandsScreenshots/BrandsScreenshots';
import BrandsReviews from '../../../components/Brands/BrandsReviews/BrandsReviews';
import HelmetMeta from '../../../components/HelmetMeta/HelmetMeta';

const OinkBingo = () => {
  return (
    <>
        <HelmetMeta title={'OinkBingo Affiliate Programme - Sign up now'} />
        <main className="lpcasino">
            <BrandsHero data={OinkBingoData.hero} />
            <BrandsService data={OinkBingoData.service} />
            <BrandsScreenshots data={OinkBingoData.screenshots} />
            <BrandsCommission data={OinkBingoData.commission} />
            <BrandsProvider data={OinkBingoData.providers} />
            <BrandsStandard data={OinkBingoData.standard} />
            <BrandsPayment data={OinkBingoData.payments} />
            <BrandsBlocks data={OinkBingoData.blocks} />
            <BrandsReviews data={OinkBingoData.reviews} />
        </main>
    </>
  )
}

export default OinkBingo