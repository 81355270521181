// services
import Licences from '../../assets/Pages/IGBConference/services/Licences.svg';
import CasinoBingoBrands from '../../assets/Pages/IGBConference/services/16CasinoBingoBrands.svg';
import Revenue from '../../assets/Pages/IGBConference/services/Revenue.svg';

// target market images
import target_markets_left from '../../assets/Pages/IGBConference/target-markets/target_markets_left.svg';
import target_markets_right from '../../assets/Pages/IGBConference/target-markets/target_markets_right.svg';

// brands
import miamijackpots from '../../assets/brands/miamijackpots.png';
import bbcasino from '../../assets/brands/bbcasino.png';
import slotsjungle from '../../assets/brands/slotsjungle.png';
import jackpotslot from '../../assets/brands/jackpotslot.png';
import spinslots from '../../assets/brands/spinslots.png';
import slotster from '../../assets/brands/slotster.png';
import vipbet from '../../assets/brands/vipbet.png';
import royalbets from '../../assets/brands/royalbets.png';
import casinoroo from '../../assets/brands/casinoroo.png';
import casino333 from '../../assets/brands/333casino.png';
import richride from '../../assets/brands/richride.png';
import diceden from '../../assets/brands/diceden.png';
import blastoffbingo from '../../assets/brands/blastoffbingo.png';
import qink from '../../assets/brands/qink.png';
import HeatBingo from '../../assets/brands/HeatBingo.png';
import WinItBingo from '../../assets/brands/WinItBingo.png';

// flags
import Caflag from '../../assets/flag/circle-flags_ca.svg';
import Dkflag from '../../assets/flag/circle-flags_dk.svg';
import Ieflag from '../../assets/flag/circle-flags_ie.svg';
import Ukflag from '../../assets/flag/circle-flags_uk.svg';

// banner images
import banner1 from '../../assets/Pages/IGBConference/banner/banner1.svg';
import banner2 from '../../assets/Pages/IGBConference/banner/banner2.svg';
import banner3 from '../../assets/Pages/IGBConference/banner/banner3.svg';
import banner4 from '../../assets/Pages/IGBConference/banner/banner4.svg';
import banner5 from '../../assets/Pages/IGBConference/banner/banner5.svg';

// powered by
import dragonfish from '../../assets/Pages/IGBConference/poweredby/dragonfish 1.png';
import GraceMedia from '../../assets/Pages/IGBConference/poweredby/GraceMedia.png';
import ProgressPlay from '../../assets/Pages/IGBConference/poweredby/ProgressPlay.png';
import SkillOnNet from '../../assets/Pages/IGBConference/poweredby/SkillOnNet.png';

// with us
import BestNewBingoSites from '../../assets/Pages/IGBConference/withus/BestNewBingoSites.png';
import bojoko from '../../assets/Pages/IGBConference/withus/bojoko.png';
import GiGMedia from '../../assets/Pages/IGBConference/withus/GiGMedia.png';
import olbgcomlogo from '../../assets/Pages/IGBConference/withus/olbgcomlogo.png';
import TrafficLab from '../../assets/Pages/IGBConference/withus/TrafficLab.png';
import WhichBingo from '../../assets/Pages/IGBConference/withus/WhichBingo.png';

export const brands = [
  { id: 0, url: `/brands/miamijackpots`, className: '', path_img: miamijackpots },
  { id: 1, url: `/brands/333ca sino`, className: '', path_img: casino333 },
  { id: 2, url: `/brands/BBC asino`, className: '', path_img: bbcasino },
  { id: 3, url: 'https://play.casinoroo.com', className: '', path_img: casinoroo },
  { id: 4, url: 'https://casino.royalbets.com', className: '', path_img: royalbets },
  { id: 5, url: 'https://casino.slotsjungle.com', className: '', path_img: slotsjungle },
  { id: 6, url: 'https://casino.slotster.com', className: '', path_img: slotster },
  { id: 7, url: 'https://casino.spinslots.com', className: '', path_img: spinslots },
  { id: 8, url: 'https://www.diceden.com', className: '', path_img: diceden },
  { id: 9, url: 'https://www.blastoffbingo.com', className: '', path_img: blastoffbingo },
  { id: 10, url: 'https://heatbingo.com', className: '', path_img: HeatBingo },
  { id: 11, url: 'https://casino.jackpotslot.com', className: '', path_img: jackpotslot },
  { id: 12, url: 'https://casino.vipbet.com', className: '', path_img: vipbet },
  { id: 13, url: 'https://casino.vipbet.com', className: '', path_img: WinItBingo },
  { id: 14, url: `/brands/OinkBingo`, className: '', path_img: qink },
  { id: 15, url: `/brands/richride`, className: '', path_img: richride },
];

export const services = [
  { id: 0, image: CasinoBingoBrands, title: '16 Casino & Bingo Brands' },
  { id: 1, image: Revenue, title: 'Up To 50% Revenue Share, CPA and Hybrid Deals' },
  { id: 2, image: Licences, title: 'Licences: UKGC, MGA, DK, Curacao' },
];

export const bannerIcons = [
  { id: 0, imageId: 'first', alt: 'first', img: banner1 },
  { id: 1, imageId: 'second', alt: 'second', img: banner2 },
  { id: 2, imageId: 'third', alt: 'third', img: banner3 },
  { id: 3, imageId: 'fourth', alt: 'fourth', img: banner4 },
  { id: 4, imageId: 'fifth', alt: 'fifth', img: banner5 },
];

export const flagIcons = [
  { id: 0, imageId: 'UK', alt: 'United kingdom', img: Ukflag },
  { id: 1, imageId: 'DK', alt: 'Denmark', img: Dkflag },
  { id: 2, imageId: 'CA', alt: 'Canada', img: Caflag },
  { id: 3, imageId: 'IR', alt: 'India', img: Ieflag },
];

export const target_markets_desc = [
  { id: 0, description: '10.2% average conversion rate from click to deposit', main: '10.2%' },
  { id: 1, description: '487% increase in FTDs in 2022', main: '487%' },
  { id: 2, description: 'Over 100 active affiliates', main: '100' },
  { id: 3, description: '90% of our partners have bespoke deals', main: '90%' },
];

export const target_markets_imgs = [
  { id: 0, imgId: 'left', img: target_markets_left },
  { id: 1, imgId: 'right', img: target_markets_right },
];

export const poweredby_imgs = [
  { id: 0, imgId: 'SkillOnNet', img: SkillOnNet },
  { id: 1, imgId: 'dragonfish', img: dragonfish },
  { id: 2, imgId: 'GraceMedia', img: GraceMedia },
  { id: 3, imgId: 'ProgressPlay', img: ProgressPlay },
];

export const withus_imgs = [
  { id: 0, imgId: 'olbgcomlogo', img: olbgcomlogo },
  { id: 1, imgId: 'TrafficLab', img: TrafficLab },
  { id: 2, imgId: 'BestNewBingoSites', img: BestNewBingoSites },
  { id: 3, imgId: 'bojoko', img: bojoko },
  { id: 4, imgId: 'WhichBingo', img: WhichBingo },
  { id: 5, imgId: 'GiGMedia', img: GiGMedia },
];
