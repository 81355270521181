import React from 'react'
import './reviews.scss'

/* This is a reuseable component that takes in 4 prop values,: 
1. singleReviewLink 
2. singleReviewLinkText
3. reviewText
4. reviewAuthor 

N/B These is displayed on the /reviews page
*/

const SingleReview = ({ singleReviewLink, singleReviewLinkText, reviewText, reviewAuthor }) => {
    return (
        <div className="single-review">
            <a href={singleReviewLink} target="_blank" rel="noopener noreferrer" className="single-review__link">{singleReviewLinkText}</a>
            <p className="single-review__paragraph">{reviewText}</p>
            <span className="single-review__author">- {reviewAuthor}</span>
        </div>
    )
}

export default SingleReview
