import RRHeroImg from '../../../assets/brands/miscellaneous/RR-Header.png';
import GamblingLicenceImg from '../../../assets/brands/BBCasino/Icons/Gambling-Licence-Icon.svg';
import AffiliateProgrammeIcon from '../../../assets/brands/BBCasino/Icons/Affiliate-Programme-Icon.svg';
import ProductFocusIcon from '../../../assets/brands/BBCasino/Icons/Product-Focus-Icon.svg';
import AllowedCountriesIcon from '../../../assets/brands/BBCasino/Icons/Allowed-Countries-Icon.svg';
import SoftwareProviderIcon from '../../../assets/brands/BBCasino/Icons/Software-Provider-Icon.svg';
import NumberOfGames from '../../../assets/brands/BBCasino/Icons/Number-of-Games.svg';
import NetentImage from '../../../assets/brands/BBCasino/Logos/Netent.png';
import Alchemy from '../../../assets/brands/BBCasino/Logos/Alchemy.png';
import BigTimeGaming from '../../../assets/brands/BBCasino/Logos/BigTimeGaming.png';
import Blueprint from '../../../assets/brands/BBCasino/Logos/BluePrint.png';
import Elk from '../../../assets/brands/BBCasino/Logos/Elk.png';
import EvolutionImage from '../../../assets/brands/BBCasino/Logos/Evolution.png';
import Microgaming from '../../../assets/brands/BBCasino/Logos/Microgaming.png';
import Northernlights from '../../../assets/brands/BBCasino/Logos/Northernlights.png';
import ThunderKick from '../../../assets/brands/BBCasino/Logos/Thunderkick.png';
import VisaIcon from '../../../assets/brands/BBCasino/Logos/Visa.png';
import MasterCard from '../../../assets/brands/BBCasino/Logos/MasterCard.png';
import Paypal from '../../../assets/brands/BBCasino/Logos/PayPal.png';
import CustomerSupport from '../../../assets/brands/BBCasino/blocks/Customer-Support-Hours.png';
import LiveChat from '../../../assets/brands/BBCasino/blocks/Live-Chat.png';
import MinimumDeposit from '../../../assets/brands/BBCasino/blocks/Minimum-Deposit.png';
import WithdrawalTimespan from '../../../assets/brands/BBCasino/blocks/Withdrawal-Timespan.png';
import ScreenshotOne from '../../../assets/brands/miscellaneous/RR-Screenshot-One.png';
import ScreenshotTwo from '../../../assets/brands/miscellaneous/RR-Screenshot-Two.png';
import ScreenshotThree from '../../../assets/brands/miscellaneous/RR-Screenshot-Three.png';
import Stars from '../../../assets/brands/BBCasino/Stars.png';
import OlbgLogo from '../../../assets/brands/BBCasino/olbg-avatar.png';
import Trustly from '../../../assets/brands/ThreeCasino/Trustly.png';
import Skrill from '../../../assets/brands/ThreeCasino/Skrill.png';
import FirstDeposit from '../../../assets/brands/ThreeCasino/deposit-1.png';
import SecondDeposit from '../../../assets/brands/ThreeCasino/deposit-2.png';
import ThirdDeposit from '../../../assets/brands/ThreeCasino/deposit-3.png';
import FourthDeposit from '../../../assets/brands/ThreeCasino/deposit-4.png';
import YourBonusSpin from '../../../assets/brands/ThreeCasino/Your-Bonus-Spin.png';


export default {
    hero: {
        title: "RichRide",
        paragraph: "Richride Affiliate Programme.This UK-Licensed casino is fast, responsive and works on every device, be it desktop or mobile.",
        button: [
            {
                id: 1,
                title: "VISIT RICHRIDE",
                link: 'https://www.richride.com/',
            },
            {
                id: 2,
                title: "PROMOTE RICHRIDE",
                link: 'https://winningpartner.com/signup',
            }
        ],
        img: RRHeroImg,
    },
    service: [
        {
            id: 1,
            icon: GamblingLicenceImg,
            serviceTitle: 'Gambling Licence',
            serviceProvider: 'UK (Acc, No. 41645)'
        },
        {
            id: 2,
            icon: AffiliateProgrammeIcon,
            serviceTitle: 'Affiliate Programme',
            serviceProvider: 'Winning Partner'
        },
        {
            id: 3,
            icon: ProductFocusIcon,
            serviceTitle: 'Product Focus',
            serviceProvider: 'Online Slots and Casino games'
        },
        {
            id: 4,
            icon: AllowedCountriesIcon,
            serviceTitle: 'Allowed Countries',
            serviceProvider: 'UK'
        },
        {
            id: 5,
            icon: SoftwareProviderIcon,
            serviceTitle: 'Software Provider',
            serviceProvider: 'Grace Media'
        },
        {
            id: 6,
            icon: NumberOfGames,
            serviceTitle: 'Number of Games',
            serviceProvider: 'Over 800 games'
        },   
    ],
    screenshots: {
        title: 'Site Screenshots',
        images: [
            ScreenshotOne,
            ScreenshotTwo,
            ScreenshotThree,
        ]
    },
    commission: {
        title: "Commission Models",
        items: [
            {
                id: 1,
                number: 1,
                text: 'Default 30% Revenue Share.',
            },
            {
                id: 2,
                number: 2,
                text: 'CPA and Hybrid deals available on request',
            }
        ]
    },
    providers: {
        title: 'Game Providers',
        paragraph: 'Great selecion of game developers, from the big names such as NetEnt and Blueprint Gaming to smaller companies like SpinPlay Games and Crazy Tooth Studio',
        images: [
            NetentImage,
            Alchemy,
            BigTimeGaming,
            Blueprint,
            Elk,
            EvolutionImage,
            Skrill,
            Microgaming,
            Northernlights,
            ThunderKick,
        ]
    },
    standard: {
        title: 'Standard Welcome Offer',
        paragraph: 'Once players sign up and start playing, they are in for a generous welcome bonus',
        item: [
            {
                id: 1,
                title: 'Deposit 1',
                paragraph: '£10-£29 for 20 Bonus Spins',
                img: FirstDeposit,
            },
            {
                id: 2,
                title: 'Deposit 2',
                paragraph: '£30-£39 for 30 Bonus Spins',
                img: SecondDeposit,
            },
            {
                id: 3,
                title: 'Deposit 3',
                paragraph: `£40-£49 for 40 Bonus Spins`,
                img: ThirdDeposit,
            },
            {
                id: 4,
                title: 'Deposit 4 ',
                paragraph: `£50 or more for 50 Bonus Spins.`,
                img: FourthDeposit,
            },
            {
                id: 5,
                title: 'Your Bonus Spins',
                paragraph: `will be playable on either Starburst, Aloha! Cluster Pays or Finn and the Swirly Spin`,
                img: YourBonusSpin,
            },
        ]
    },
    payments: {
        title: 'Payment Methods',
        paragraph: 'Our punters get a great number of the most popular payment options. All deposit methods are fast safe and secure.',
        img: [
            VisaIcon,
            MasterCard,
            Paypal,
            Trustly,
        ]
    },
    blocks: [
        {
            id: 1,
            title: 'Minimum Deposit',
            text: '£10 to choose from ',
            img: MinimumDeposit,
        },
        {
            id: 2,
            title: 'Withdrawal Timespan',
            text: '1-3 working days',
            img: WithdrawalTimespan,
        },
        {
            id: 3,
            title: 'Customer Support Hours',
            text: 'Every day 24/7',
            img: LiveChat,
        },
        {
            id: 4,
            title: 'Customer Support Methods',
            text: 'Live Chat, Email, Phone',
            img: CustomerSupport,
        },
    ],
    reviews: {
        title: 'Partner Reviews',
        item: [
            {
                id: 1,
                image: OlbgLogo,
                paragraph: 'OLBG’s collaboration with Winning Partner has been propelled by their transparency and responsiveness. The team is always eager to provide guidance and assistance whenever required, and we are looking forward to continued success with their brands.',
                rating: Stars,
                siteText: 'Olbg.com',
                siteLink: 'https://www.olbg.com/'
            }
        ],
        button: [
            {
                id: 1,
                title: "VISIT RICHRIDE",
                link: 'https://www.richride.com/',
            },
            {
                id: 2,
                title: "PROMOTE RICHRIDE",
                link: 'https://winningpartner.com/signup',
            }
        ],
    }
}
