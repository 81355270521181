import HeroImg from '../../../assets/brands/OinkBingo/ImagePhone.png';
import GamblingLicenceImg from '../../../assets/brands/BBCasino/Icons/Gambling-Licence-Icon.svg';
import AffiliateProgrammeIcon from '../../../assets/brands/BBCasino/Icons/Affiliate-Programme-Icon.svg';
import ProductFocusIcon from '../../../assets/brands/BBCasino/Icons/Product-Focus-Icon.svg';
import AllowedCountriesIcon from '../../../assets/brands/BBCasino/Icons/Allowed-Countries-Icon.svg';
import SoftwareProviderIcon from '../../../assets/brands/BBCasino/Icons/Software-Provider-Icon.svg';
import NumberOfGames from '../../../assets/brands/BBCasino/Icons/Number-of-Games.svg';
import BallyWulff from '../../../assets/brands/OinkBingo/BallyWulff.png';
import LightningBox from '../../../assets/brands/OinkBingo/LightningBox.png';
import NextGen from '../../../assets/brands/OinkBingo/NextGen.png';
import EvolutionGaming from '../../../assets/brands/BBCasino/Logos/Evolution.png';
import GreenTube from '../../../assets/brands/OinkBingo/Greentube.png';
import BigTimeGaming from '../../../assets/brands/OinkBingo/BigTimeGaming.png';
import Elk from '../../../assets/brands/OinkBingo/Elk.png';
import BulletProof from '../../../assets/brands/OinkBingo/Bulletproof.png';
import High5 from '../../../assets/brands/OinkBingo/High5.png';
import Inspired from '../../../assets/brands/OinkBingo/Inspired.png';
import Visa from '../../../assets/brands/OinkBingo/Visa.png';
import Wire from '../../../assets/brands/OinkBingo/Wire.png';
import Mastercard from '../../../assets/brands/OinkBingo/Mastercard.png';
import Paypal from '../../../assets/brands/BBCasino/Logos/PayPal.png';
import CustomerSupport from '../../../assets/brands/BBCasino/blocks/Customer-Support-Hours.png';
import LiveChat from '../../../assets/brands/BBCasino/blocks/Live-Chat.png';
import MinimumDeposit from '../../../assets/brands/BBCasino/blocks/Minimum-Deposit.png';
import WithdrawalTimespan from '../../../assets/brands/BBCasino/blocks/Withdrawal-Timespan.png';
import FirstScreenshot from '../../../assets/brands/OinkBingo/1screenshot.png'
import SeconScreenshot from '../../../assets/brands/OinkBingo/2screenshot.png';
import ThirdScreenshot from '../../../assets/brands/OinkBingo/3screenshot.png';
import ReviewLogo from '../../../assets/brands/BBCasino/Review-Logo.png';
import Stars from '../../../assets/brands/BBCasino/Stars.png';
import OlbgLogo from '../../../assets/brands/BBCasino/olbg-avatar.png';
import OfferDesktop from '../../../assets/brands/OinkBingo/OfferDesktop.png';
import OfferMobile from '../../../assets/brands/OinkBingo/OfferMobile.png';

export default {
    hero: {
        title: "OINK BINGO",
        paragraph: "OinkBingo Affiliate Programme. This UK-licensed casino is fast, responsive and works on every device, be it desktop or mobile",
        button: [
            {
                id: 1,
                title: "Visit Oink Bingo",
                link: 'https://www.oinkbingo.com/',
            },
            {
                id: 2,
                title: "Promote Oink Bingo",
                link: 'https://winningpartner.com/signup',
            }
        ],
        img: HeroImg,
    },
    service: [
        {
            id: 1,
            icon: GamblingLicenceImg,
            serviceTitle: 'Gambling Licence',
            serviceProvider: 'UKGC 58267',
            img: '',
        },
        {
            id: 2,
            icon: AffiliateProgrammeIcon,
            serviceTitle: 'Affiliate Programme',
            serviceProvider: 'Winning Partner',
            img: '',
        },
        {
            id: 3,
            icon: ProductFocusIcon,
            serviceTitle: 'Product Focus',
            serviceProvider: 'Online Slots and Bingo',
            img: '',
        },
        {
            id: 4,
            icon: AllowedCountriesIcon,
            serviceTitle: 'Allowed Countries',
            serviceProvider: 'The UK and Ireland',
            img: '',
        },
        {
            id: 5,
            icon: SoftwareProviderIcon,
            serviceTitle: 'Software Provider',
            serviceProvider: 'DragonFish',
            img: '',
        },
        {
            id: 6,
            icon: NumberOfGames,
            serviceTitle: 'Number of Games',
            serviceProvider: 'Over 900 games',
            img: '',
        },   
    ],
    screenshots: {
        title: 'Site Screenshots',
        images: [
            FirstScreenshot,
            SeconScreenshot,
            ThirdScreenshot,
        ]
    },
    commission: {
        title: "Commission Models",
        items: [
            {
                id: 1,
                number: 1,
                text: 'Default 30% Revenue Share.',
            },
            {
                id: 2,
                number: 2,
                text: 'CPA and Hybrid deals available on request',
            }
        ]
    },
    providers: {
        title: 'Game Providers',
        paragraph: 'Great selection of game developers, from the big names such as NetEnt and Blueprint Gaming to smaller companies like SpinPlay Games and Crazy Tooth Studio',
        images: [
            BallyWulff,
            LightningBox,
            NextGen,
            EvolutionGaming,
            GreenTube,
            BigTimeGaming,
            Elk,
            BulletProof,
            High5,
            Inspired,
        ]
    },
    standard: {
        title: 'Standard Welcome Offer',
        paragraph: 'Once players sign up and start playing, they are in for a generous welcome bonus',
        item: [
            {
                id: 1,
                title: '',
                paragraph: [
                    "A bingo bonus of 100% on your first deposit up to a maximum of £50",
                    "A game bonus of 100% on your first deposit up to a maximum of £50",
                    "20 free spins on selected games - Valid only on Temple of Iris 2"
                ],
                img: OfferDesktop,
                imgMobile: OfferMobile,
            },
            
        ],
    },
    payments: {
        title: 'Payment Methods',
        paragraph: 'Our punters get a great number of the most popular payment options. All deposit methods are fast safe and secure.',
        img: [
            Visa,
            Mastercard,
            Wire,
            Paypal,
        ]
    },
    blocks: [
        {
            id: 1,
            title: 'Minimum Deposit',
            text: '10 GBP',
            img: MinimumDeposit,
        },
        {
            id: 2,
            title: 'Withdrawal Timespan',
            text: '2-3 working days',
            img: WithdrawalTimespan,
        },
        {
            id: 3,
            title: 'Customer Support Hours',
            text: 'Phone and Email',
            img: LiveChat,
        },
        {
            id: 4,
            title: 'Customer Support Methods',
            text: 'Everyday 24/7',
            img: CustomerSupport,
        },
    ],
    reviews: {
        title: 'Partner Reviews',
        item: [
            {
                id: 1,
                image: ReviewLogo,
                paragraph: 'We have been working with Winning Partners for a long time now, and have consistently been impressed by their professionalism and support. We are particularly happy with our partnership for Oink Bingo, which has proven to be extremely popular with our users.',
                rating: Stars,
                siteText: 'Bojoko.com',
                siteLink: "https://bojoko.com/"
            },
        ],
        button: [
            {
                id: 1,
                title: "Visit Oink Bingo",
                link: 'https://www.oinkbingo.com/',
            },
            {
                id: 2,
                title: "Promote Oink Bingo",
                link: 'https://winningpartner.com/signup',
            }
        ],
    }
}