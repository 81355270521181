import { useCallback, useRef, useState } from "react";
import { NavLink, Redirect } from "react-router-dom";
import { Alert, Button, Container, Grid, TextField } from "@mui/material";
import { CSSTransition } from "react-transition-group";
import ReCAPTCHA from "react-google-recaptcha";
import "./login.scss";
import useAuth from "../../auth/useAuth";

export const Login = () => {
  const auth = useAuth();

  const [affiliate, setAffiliate] = useState({
    email: "",
    password: "",
  });
  const [status, setStatus] = useState({
    success: false,
    error: false,
    message: "",
  });

  const [captcha, setCaptcha] = useState("");
  const [erCaptcha, setErCaptcha] = useState(false);
  const reCaptchaRef = useRef();
  const handleChange = useCallback(
    (e) => {
      setAffiliate({ ...affiliate, [e.target.name]: e.target.value });
    },
    [setAffiliate, affiliate],
  );
  const handleSubmit = async (email, password) => {
    setStatus({ error: false });
    setErCaptcha(false);
    if (captcha) {
      auth.login(email, password)
        .catch((err) => {
          setStatus({
            error: true,
            message: "Unrecognised Details",
          });
        });
    } else {
      setErCaptcha(true);
    }
  };

  const handleCaptchaChange = useCallback(
    (e) => {
      setCaptcha(e);
    },
    [setCaptcha],
  );

  return (
    <Container className={"center-block"} maxWidth="sm">
      {auth.isAuthenticated && <Redirect to="/admin/overview" />}
      <h1 className="sign-in-title">Sign in</h1>
      <CSSTransition
        in={status.error}
        timeout={500}
        classNames="my-node"
        unmountOnExit
      >
        <Alert severity="error">{status.message ? status.message : "Wrong login or password"}</Alert>
      </CSSTransition>
      <CSSTransition
        in={status.success}
        timeout={500}
        classNames="my-node"
        unmountOnExit
      >
        <Alert severity="success">Login successful</Alert>
      </CSSTransition>
      {erCaptcha && <Alert severity="error">Please enter Captcha</Alert>}
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit(affiliate.email, affiliate.password);
        }}
      >
        <Grid item xs={12}>
          <TextField
            required
            id="standard-required"
            label="Email"
            type="text"
            variant="outlined"
            name="email"
            defaultValue={affiliate.email}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="standard-password-input"
            label="Password"
            type="password"
            autoComplete="current-password"
            variant="outlined"
            name="password"
            defaultValue={affiliate.password}
            onChange={handleChange}
          />
        </Grid>
        <Grid
          item
          container
          xs={12}
          direction="row"
          justify="center"
          alignItems="center"
          style={{ marginTop: "-10px" }}
        >
          <NavLink to="/forgot">Forgot password?</NavLink>
        </Grid>
        <Grid
          item
          container
          xs={12}
          direction="row"
          justify="center"
          alignItems="center"
          style={{ marginTop: "-10px" }}
        >
          <ReCAPTCHA
            ref={reCaptchaRef}
            sitekey="6Lc6O9AUAAAAAF6myYURrvZwW43E4ShPeKOVKCHe"
            onChange={handleCaptchaChange}
          />
        </Grid>
        <Grid
          item
          container
          xs={12}
          direction="row"
          justify="center"
          alignItems="center"
        >
          <Button
            size="large"
            variant="contained"
            type="submit"
            color="primary"
          >
            Sign in
          </Button>
        </Grid>
      </form>
    </Container>
  );
};
